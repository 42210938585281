import React, { useCallback, useEffect, useState, KeyboardEvent } from 'react';
import {
    Typography,
    Grid,
    Button,
    Container,
    Box,
    TextField,
    makeStyles
} from '@material-ui/core';
import {
    LockOpen as LockOpenIcon,
    Create as CreateIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import isEmail from 'validator/lib/isEmail';
import { AxiosResponse } from 'axios';

import moveit4u_logo from '../../../Images/moveit4u-logo.png';
import { routes } from '../../../routes';
import { PRIMARY_COLOR, TERTIARY_COLOR } from '../../../Styles/colors';
import { UsersApi } from '../../../Api/Users/Users';
import { ILoginResponse } from '../../../../../Application/DTOs/UsersDto/UsersDto.type';
import { setUserLoginResponse } from '../../../Redux/User/actions';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY, USER_PRIVILEGES, USER_ROLES } from '../../../Types/Constants';
import { setLoader } from '../../../Redux/Loader/actions';
import { AdminSettings } from "../../../Api/AdminSettings/AdminSettings";

const useStyles = makeStyles(() => ({
    loginBox: {
        backgroundColor: 'white',
        padding: '40px 15px',
        borderRadius: '20px',
        boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)',
    },
    logo: {
        width: '210px',
        height: '210px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    gap: {
        marginTop: '25px',
    },
    btnSignIn: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    btnRegisterCompany: {
        backgroundColor: PRIMARY_COLOR,
        color: 'white',
        '&:hover': {
            backgroundColor: PRIMARY_COLOR,
        },
    },
    btnRegisterDriver: {
        backgroundColor: TERTIARY_COLOR,
        color: 'white',
        '&:hover': {
            backgroundColor: TERTIARY_COLOR,
        },
    },
    btnForgotPassword: {
        fontStyle: 'italic',
        background: 'none',
        border: 'none',
        padding: '0',
        textDecoration: 'underline',
        color: '#069',
        cursor: 'pointer',
    },
}));

const usersApi = new UsersApi();
const storage = new WebStorage(false);
const { TRANSPORT_MANAGEMENT, SEA_FREIGHT_MANAGEMENT } = USER_PRIVILEGES;
const { ADMIN_USER, DRIVER_MANAGER } = USER_ROLES;

function Login() {
    const classes = useStyles();
    const [t] = useTranslation();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const adminSettingsApi = new AdminSettings();

    const getNotifications = async () => {
        try {
            const response = await adminSettingsApi.getNotifications();
            const notifications = response.data;

            if (notifications.length > 0) {
                toast.info(
                    <ul>
                        {notifications.map((notification) => (
                            <li key={notification.id}>{notification.message}</li>
                        ))}
                    </ul>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const handleVerifyAndLoginResponse = useCallback(
        (response: AxiosResponse, routePath: any) => {
            const loginCred: ILoginResponse = response.data;
            const privileges = loginCred.user.privileges;
            const userType = loginCred.user.type;

            storage.store(TOKEN_STORAGE_KEY, loginCred.token);

            dispatch(setUserLoginResponse(loginCred.user));

            const onreroute = `/home/dashboard/main`;
            history.push(onreroute);

            // if (userType === ADMIN_USER) {
            //     if (privileges.includes(TRANSPORT_MANAGEMENT) || privileges.includes(SEA_FREIGHT_MANAGEMENT)) {
            //         history.push(onreroute);
            //     }
            // } else if (userType === DRIVER_MANAGER) {
            //     history.push(
            //         `${routes.home.root}${routes.home.transport_management.root}${routes.home.jobs.show_jobs.root}${routes.home.jobs.show_jobs.in_progress}`
            //     );
            // }
            //  else {
            //     const searchParams = new URLSearchParams(window.location.search);
            //     const isSearching = searchParams.has('search');
            //     if (routePath === '/login' && isSearching) {
            //       const searchType = searchParams.get('search');
            //       const searchValue = searchParams.get('value');
            //       if (searchType === 'delivery') {
            //         history.push(
            //           `${routes.home.root}${routes.home.report.root}${routes.home.report.scheduled_delivery_report}?id=${searchValue}`
            //         );
            //       } else {
            //         history.push(onreroute);
            //       }
            //     } else {
            //       history.push(onreroute);
            //     }
            //   }

        },
        [dispatch, history]
    );

    const handleKeyboardEvent = (e: KeyboardEvent<HTMLImageElement>) => {
        if (e.key === 'Enter') {
            handleSignInClick();
        }
    };
    function getNotificationColor(type) {
        switch (type) {
            case 'info':
                return 'blue';
            case 'warning':
                return 'orange';
            case 'error':
                return 'red';
            case 'success':
                return 'green';
            default:
                return 'black';
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const currentToken = storage.retrieve(TOKEN_STORAGE_KEY);
            if (currentToken) {
                // dispatch(setLoader(true));
                try {
                    const r = await usersApi.verify(currentToken);
                    dispatch({ type: 'LOGIN_SUCCESS', payload: r });
                    handleVerifyAndLoginResponse(r, history.location.pathname);
                    getNotifications();

                } catch (error) {
                    console.error(error);
                    storage.clear(TOKEN_STORAGE_KEY);
                    dispatch(setUserLoginResponse(null));
                } finally {
                    dispatch(setLoader(false));
                }
            }
        };

        fetchData();
    }, [history, dispatch, handleVerifyAndLoginResponse]);

    const handleRegisterCompanyClick = () => {
        history.push(routes.landing.registerCompany);
    };

    const handleRegisterDriverClick = () => {
        history.push(routes.landing.registerDriver);
    };

    const handleRegisterCarrierClick = () => {
        history.push(routes.landing.registerCarrier);
    };

    const handleRegisteCustomerClick = () => {
        history.push(routes.landing.registerCustomer);
    };

    const handleSignInClick = () => {
        if (username === '') {
            toast.warn(t('please_provide_username'));
            return;
        } else if (!isEmail(username)) {
            toast.warn(t('email_invalid_format'));
            return;
        }

        if (password === '') {
            toast.warn(t('enter_user_pass_warn'));
            return;
        }

        if (username !== '' && password !== '') {
            dispatch(setLoader(true));

            usersApi
                .login(username, password)
                .then((response) => {
                    handleVerifyAndLoginResponse(response, history.location.pathname);
                    dispatch(setLoader(false));

                })
                .catch((err: any) => {
                    dispatch(setLoader(false));
                    console.log('err', err);
                    toast.error(t(err.response?.data ?? 'error_signing_in'));
                });
        }
    };

    const onForgotPasswordClick = useCallback(() => {
        history.push(routes.landing.forgotPassword);
    }, [history]);

    return (
        <Container maxWidth="sm">
            <Box className={classes.loginBox}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid xs={12} style={{ textAlign: 'center' }} item>
                        <LazyLoadImage
                            className={classes.logo}
                            title="Moveit4u"
                            src={moveit4u_logo}
                            alt="Moveit4u"
                            effect="blur"
                        />
                    </Grid>
                    <Grid className={classes.gap} xs={12} item>
                        <Typography align="center" variant="h4" component="h4">
                            {t('LOGIN')}
                        </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            fullWidth
                            label={t('Username')}
                            onKeyPress={handleKeyboardEvent}
                            type="text"
                            variant="outlined"
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyPress={handleKeyboardEvent}
                            fullWidth
                            label={t('Password')}
                            type="password"
                            variant="outlined"
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <Button
                            variant="contained"
                            className={classes.btnSignIn}
                            startIcon={<LockOpenIcon />}
                            fullWidth
                            onClick={handleSignInClick}
                        >
                            {t('Sign In')}
                        </Button>
                    </Grid>
                    <Grid xs={12} item>
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('Forgot Password?')}{' '}
                            <button onClick={onForgotPasswordClick} className={classes.btnForgotPassword}>
                                {t('Click Here')}
                            </button>
                        </Typography>
                    </Grid>

                    <Grid className={classes.gap} xs={12} item></Grid>
                    <Grid xs={12} md={4} item>
                        <Button
                            variant="contained"
                            className={classes.btnRegisterCompany}
                            startIcon={<CreateIcon />}
                            fullWidth
                            onClick={handleRegisterCompanyClick}
                        >
                            {t('Register as Company')}
                        </Button>
                    </Grid>
                    <Grid xs={12} md={4} item>
                        <Button
                            variant="contained"
                            className={classes.btnRegisterDriver}
                            startIcon={<CreateIcon />}
                            fullWidth
                            onClick={handleRegisterDriverClick}
                        >
                            {t('Register as Driver')}
                        </Button>
                    </Grid>
                    <Grid xs={12} md={4} item>
                        <Button
                            variant="contained"
                            className={classes.btnRegisterDriver}
                            startIcon={<CreateIcon />}
                            fullWidth
                            onClick={handleRegisterCarrierClick}
                        >
                            {t('Register as Carrier')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default Login;
