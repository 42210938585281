import React, { useState, useEffect } from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import './popup.css'
const useStyles = makeStyles((theme) => ({
    MuiSelect: {
        backgroundColor: 'transparent',
        // fontSize: labelSize,
    },
    transparentInput: {
        backgroundColor: 'transparent',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            // fontSize: labelSize,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
            // fontSize: labelSize,
        }
    },
    dateField: {
        width: '100%',
        '& input': {
            backgroundColor: 'white',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '10px',
        },
        '&:focus-within input': {
            borderColor: theme.palette.primary.main,
        },
    },
    timeField: {
        width: '150px',
        '& input': {
            backgroundColor: 'white',
            borderRadius: '4px',
        },
        '& label': {
            color: '#333',
        },
    },
    customInput: {
        width: '100%',
        height: '2rem',
        '& input': {
            padding: '10px', // Consistent padding
        },
    },
}));

const CustomDateTimePicker = ({
    date_value,
    onDateChange,
    readOnlyBidder,
    minimumDate,
}) => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [time, setTime] = useState<string>('');

    useEffect(() => {
        if (date_value) {
            const parsedDate = new Date(date_value);
            setSelectedDate(parsedDate);
            // const hours = parsedDate.getUTCHours().toString().padStart(2, '0');
            // const minutes = parsedDate.getUTCMinutes().toString().padStart(2, '0');
            // setTime(`${hours}:${minutes}`);
        }
    }, [date_value]);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        if (date) {
            // const isoString = date.toISOString();
            onDateChange(date);
        }
        // if (date) {
        //     // Create a moment object to preserve local time
        //     const localDateTime = moment(date).set({
        //         hours: selectedDate.getHours(),
        //         minutes: selectedDate.getMinutes(),
        //     });

        //     // Update state
        //     setSelectedDate(localDateTime.toDate()); // Convert back to Date object if needed
        //     // Pass the local date and time as an ISO string or formatted string
        //     onDateChange(localDateTime.format()); // Or use .toISOString() if UTC is needed
        // } else {
        //     setSelectedDate(null);
        //     onDateChange(null); // Handle null case if needed
        // }
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTime = event.target.value;
        setTime(newTime);
        if (selectedDate) {
            const hours = parseInt(newTime.split(':')[0]);
            const minutes = parseInt(newTime.split(':')[1]);
            const combinedDateTime = new Date(selectedDate);
            combinedDateTime.setUTCHours(hours, minutes, 0, 0);
            const isoString = combinedDateTime.toISOString();
            onDateChange(isoString);
        }
    };

    return (
        <Grid item container spacing={2} direction="row">
            <Grid item xs={6} sm={6} md={4}>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    readOnly={readOnlyBidder}
                    minDate={minimumDate ? moment(minimumDate).toDate() : undefined}
                    customInput={
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Date"
                            InputLabelProps={{ shrink: true }}
                            className={classes.customInput}
                            InputProps={{
                                inputProps: {
                                    style: { height: '2rem' }, // Make sure it takes full height
                                },
                                readOnly: readOnlyBidder,
                            }}
                        />
                    }

                />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
                <TextField
                    id="time"
                    label="Time"
                    fullWidth
                    variant='outlined'
                    type="time"
                    value={time}
                    onChange={handleTimeChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }} // 5 min
                    className={classes.timeField}
                    readOnly={readOnlyBidder}
                />
            </Grid>
        </Grid>
    );
};

export default CustomDateTimePicker;
