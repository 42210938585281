import * as Yup from "yup";
const nameRegex = /^[a-zA-Z\s]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
const zipCodeRegex = /^\d{5,6}$/;
export const validationCreateJobSchema = Yup.object({
  company: Yup.string().required("This field is required."),
  movementType: Yup.string().required("This field is required."),
  shipmentNo: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(20, "This field cannot exceed 20 characters."),
  customer: Yup.string().optional(),
  jobType: Yup.string().required("This field is required."),
  m141: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  customerReference: Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  tripType: Yup.string().required("This field is required."),
  transportType: Yup.string().required("This field is required."),
  temperature: Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  groupType: Yup.string().optional(),
  goodsDesc:Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(50, "This field cannot exceed 50 characters."),
  totalQty: Yup.number().optional(),
  netWeight: Yup.number().optional(),
  palletAcc:Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  palletAccNumber: Yup.string()
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  grossWeight: Yup.number().optional(),
  volume: Yup.number().optional(),
  palletAccAction: Yup.string().optional(),
  containerNo:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  wharfSlot:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  sealNo: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  cntrlfd:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  ecSealNo: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  declaredGrossWeight:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  document: Yup.string().optional(),
  serviceType: Yup.string().optional(),
  jobServiceCharges: Yup.string().optional(),
  postTo: Yup.string().optional(),
  buyPrice:  Yup.number().optional(),
  sellPrice: Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true)
  .max(60, "This field cannot exceed 60 characters."),
  jobInstruction:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  containerOverwight: Yup.boolean().optional(),
  jobDetails: Yup.array().of(
    Yup.object().shape({
      jobDetailPackage: Yup.string().optional(),
      jobDetailQty: Yup.number().optional(),
      jobDetailSKU:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(15, "This field cannot exceed 15 characters."),
      jobDetailDesc: Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(50, "This field cannot exceed 50 characters."),
      jobDetailLot:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true),
      jobDetailHeight: Yup.number().optional(),
      jobDetailWidth: Yup.number().optional(),
      jobDetailLength: Yup.number().optional(),
      jobDetailNetWeight:  Yup.number().optional(),
    })
  ),
  jobDetailVesselName:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  jobDetailETA: Yup.date().optional(),
  jobDetailVoyage:  Yup.string() 
  .trim('This field cannot include leading and trailing spaces')
  .strict(true),
  trip: Yup.array().of(
    Yup.object().shape({
      tripPickVehicleType: Yup.string().required("This field is required."),
      tripPickGroupType: Yup.string().required("This field is required."),
      tripPickCountry: Yup.string().required("This field is required."),
      tripPickState: Yup.string().required("This field is required."),
      tripPickShipId: Yup.string().required("This field is required."),
      tripPickAddress: Yup.string().required("This field is required."),
      tripPickDate: Yup.date().required("This field is required."),
      tripPickTime: Yup.string().required("This field is required."),
      tripPickJobRequirement:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(150, "This field cannot exceed 150 characters."),
      tripDeliverVehicleType: Yup.string().required("This field is required."),
      tripDeliverGroupType: Yup.string().required("This field is required."),
      tripDeliverCounty: Yup.string().required("This field is required."),
      tripDeliverState: Yup.string().required("This field is required."),
      tripDeliverShipId: Yup.string().required("This field is required."),
      tripDeliverAddress: Yup.string().required("This field is required."),
      tripDeliverDate: Yup.date().required("This field is required."),
      tripDeliverTime: Yup.string().required("This field is required."),
      tripDeliverJobRequirement:  Yup.string() 
      .trim('This field cannot include leading and trailing spaces')
      .strict(true)
      .max(150, "This field cannot exceed 150 characters."),
    })
  ),
});

export const validationAddContainerModal = Yup.object({
  container_no: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    seal_no: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    declared_gross_weight: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    wharf_slot: Yup.date()
    .required("This field is required."),
    
    empty_container_release_number: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
    
    cntr_last_day: Yup.date()
    .required("This field is required."),
});


export const validationJobTypeModal = Yup.object({
  jobtype: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
});


export const validationTypeofPackageModal = Yup.object({
  typeofpackage: Yup.string()
    .trim('This field cannot include leading and trailing spaces')
    .strict(true)
    .max(60, "This field cannot exceed 60 characters.")
    .required("This field is required."),
});
