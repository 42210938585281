import React from 'react';
import { Drawer, List, IconButton, Divider, makeStyles } from '@material-ui/core';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FiChevronsLeft } from 'react-icons/fi';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import moveit4u_logo from '../../../Images/m4logo.png'
import moveit4u_old from '../../../Images/moveit4u-logo.png'

import ListSubheader from '@material-ui/core/ListSubheader';
import SubMenu from './SubMenu';
import { PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY_COLOR } from '../../../Styles/colors';
import './Sidebar.css';
import { SidebarData } from './SidebarData';
import * as SIDEBAR from './Sidebar.types';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: (props: SIDEBAR.ISidebarProps) => props.themeStyle.sidebar_width,
    backgroundColor: (props: SIDEBAR.ISidebarProps) => props.themeStyle.sidebar_color,
    color: (props: SIDEBAR.ISidebarProps) => props.themeStyle.sidebar_font_color,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: '100px !important'

  },
  logo: {
    height: '50px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer'
  },
  sidebarContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  sidebarToggle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  listHeader: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
}));

function SidebarV2(props: SIDEBAR.ISidebarProps) {
  const { isSidebarOpen, toggleSideBar, userType,subUserType, themeStyle } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const handleClick = () => {
    const onreroute = `/home/dashboard/main`;
    history.push(onreroute);
  };
  const handleBackIconClick = () => {
    toggleSideBar(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        {
          (props.themeStyle.name == 'light') ?
            <img alt="Moveit4u" className={classes.logo} src={moveit4u_logo} onClick={handleClick} />

            :
            <img alt="Moveit4u" className={classes.logo} src={moveit4u_old} onClick={handleClick} />
        }
        {/* <IconButton color="inherit" onClick={handleBackIconClick}>
          <KeyboardBackspaceIcon />
        </IconButton> */}
      </div>
      <Divider />
      <div className={classes.sidebarContent}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          // style={{ backgroundColor: 'red' }}
          subheader={
            <ListSubheader
              className={classes.listHeader}
              color="inherit"
              component="div"
              id="nested-list-subheader"
            >
            </ListSubheader>
          }
        >
          {SidebarData( userType, props.themeStyle,subUserType).map((item, index) => {
            if (item.hide) return null;
            return <SubMenu item={item} key={index} themeStyle={themeStyle} />;
          })}
        </List>
      </div>
    </Drawer>
  );
}

export default SidebarV2;