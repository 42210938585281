import React, { useCallback, useEffect } from 'react';
import ModalWrapper from '../wrappers/ModalWrapper';
import { Formik, Form } from 'formik';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import InputBox from '../ui/InputBox';
import { validationAddContainerModal } from '../lib/validationSchema';
import { useDropzone } from 'react-dropzone';
import SelectorComp from '../ui/SelectorComp';
import { DOCS } from '../lib/constants';
import classes from "../styles/createjobv2.module.css";
import CustomDatePickerNew from '../ui/CustomDatePickerNew';

type DocType = typeof DOCS[number]['value'];

interface AddContainerModalProps {
    open: boolean;
    close: () => void;
    onAddJobContainer: any;
    initialValues: any;
    setFileContainer: React.Dispatch<React.SetStateAction<File | null>>;
    fileContainer:File | null;
    docTypeContainer:string;
    setDocTypeContainer: React.Dispatch<React.SetStateAction<DocType | ''>>;
}


const AddContainerModal = ({ open, close, onAddJobContainer, initialValues = null, fileContainer, setFileContainer, setDocTypeContainer, docTypeContainer }: AddContainerModalProps) => {
    useEffect(() => {
        if (initialValues?.documentType) {
            setDocTypeContainer(initialValues.documentType);
        }
        if (initialValues?.documentFile?.path) {
            const fakeFile = new File([], initialValues.documentFile.path);
            setFileContainer(fakeFile);
        }
    }, [initialValues]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setFileContainer(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
    });

    const defaultValues = {
        container_no: '',
        seal_no: '',
        declared_gross_weight: '',
        wharf_slot: '',
        empty_container_release_number: '',
        cntr_last_day: '',
        over_weight: false,
    };

    const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
        onAddJobContainer({ ...values, documentType: docTypeContainer, documentFile: fileContainer });
        setSubmitting(false);
        resetForm();
        setDocTypeContainer('');
        setFileContainer(null);
        close();
    };

    const handleClose = (resetForm: () => void) => {
        if (initialValues) {
            close();
        } else {
            resetForm();
            setDocTypeContainer('');
            setFileContainer(null);
            close();
        }
    };
    return (
        <Formik
            initialValues={initialValues || defaultValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationAddContainerModal}
        >
            {({ isSubmitting, handleChange, setFieldValue, values, errors, touched, resetForm }) => (
                <ModalWrapper
                    open={open}
                    onClose={() => handleClose(resetForm)}
                    heading={initialValues ? 'Edit Container Details' : 'Add Container Details'}
                >
                    <Form>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.over_weight}
                                    onChange={(e) => setFieldValue(`over_weight`, e.target.checked)}
                                />
                            }
                            label="Tick if container is overweight"
                            style={{ margin: "20px 0" }}
                        />
                        <Grid container spacing={2} style={{ margin: "10px 0" }}>
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <InputBox
                                    value={values.container_no}
                                    onChange={handleChange}
                                    name={`container_no`}
                                    type="text"
                                    label="Container No."
                                    error={errors.container_no && touched.container_no ? errors.container_no : null}
                                />
                                <InputBox
                                    value={values.empty_container_release_number}
                                    onChange={handleChange}
                                    name={`empty_container_release_number`}
                                    type="text"
                                    label="EC Seal No."
                                    error={errors.empty_container_release_number && touched.empty_container_release_number ? errors.empty_container_release_number : null}
                                />
                                 <CustomDatePickerNew
                                    selectedDate={values.cntr_last_day}
                                    name="cntr_last_day"
                                    placeholder="Cntr Last Day"
                                    error={errors.cntr_last_day && touched.cntr_last_day ? errors.cntr_last_day : null}
                                    handleDateChange={(date: Date) => {
                                        setFieldValue(`cntr_last_day`, date);
                                      }}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <InputBox
                                    value={values.seal_no}
                                    onChange={handleChange}
                                    name="seal_no"
                                    type="text"
                                    label="Seal No."
                                    error={errors.seal_no && touched.seal_no ? errors.seal_no : null}
                                />
                               
                                <InputBox
                                    value={values.declared_gross_weight}
                                    onChange={handleChange}
                                    name="declared_gross_weight"
                                    type="text"
                                    label="Declared Gross Weight (kg)"
                                    error={errors.declared_gross_weight && touched.declared_gross_weight ? errors.declared_gross_weight : null}
                                />
                                <CustomDatePickerNew
                                    selectedDate={values.wharf_slot}
                                    name="wharf_slot"
                                    placeholder="Wharf Slot"
                                    error={errors.wharf_slot && touched.wharf_slot ? errors.wharf_slot : null}
                                    handleDateChange={(date: Date) => {
                                        setFieldValue(`wharf_slot`, date);
                                      }}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <div {...getRootProps({ className: 'dropzone' })} className={classes.draganddrop}>
                                    <input {...getInputProps()} />
                                    {fileContainer ? (
                                        <p>Selected file: {fileContainer.name}</p>
                                    ) : (
                                        <p>Drag 'n' drop to upload, or browse documents</p>
                                    )}
                                </div>
                                <SelectorComp
                                    label="Document Type"
                                    value={docTypeContainer}
                                    onChange={(e) => {
                                        setDocTypeContainer(e.target.value as DocType);
                                        setFieldValue("documentType", e.target.value);
                                    }}
                                    options={DOCS}
                                    name="documentType"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            color="secondary"
                            variant="contained"
                            disabled={isSubmitting}
                            style={{ margin: '20px 0' }}
                        >
                            Submit
                        </Button>
                    </Form>
                </ModalWrapper>
            )}
        </Formik>
    );
};

export default AddContainerModal;
