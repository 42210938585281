import { FormControl, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    transparentInput: {
        backgroundColor: 'transparent',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
        }
    },
    dateField: {
        width: '100%',
        '& input': {
            backgroundColor: 'white',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '10px',
        },
        '&:focus-within input': {
            borderColor: theme.palette.primary.main,
        },
    },
    customInput: {
        width: '100%',
        height: '3.45rem',
        '& input': {
            padding: '10px',
        },
    },
}));

const CustomDatePicker = ({
    selectedDate,
    handleDateChange,
    readOnly = false,
    placeholder = "DD/MM/YYYY",
    name,
    fullWidth = true,
    error=undefined,
    minDate=undefined,
    maxDate=undefined,
}) => {
    const classes = useStyles();
    return (
        <FormControl fullWidth variant="outlined" >
        <TextField
            type="date"
            variant="outlined"
            fullWidth={fullWidth}
            label={placeholder}
            InputLabelProps={{ shrink: true }}
            className={classes.customInput}
            name={name}
            value={selectedDate}
            onChange={handleDateChange}
            error={error}
            InputProps={{
                inputProps: {
                    min: minDate,
                    max:maxDate,
                    style: { height: '2rem' },  
                },
                readOnly: readOnly,
            }}
            helperText={error}
        />
            </FormControl>

    );
};

export default CustomDatePicker;
