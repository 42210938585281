import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const SectionHeader = ({ title, subtitle = 'Moveit4U' }) => {
    return (
        <Grid item md={3} container direction="column" alignItems="flex-start" style={{ lineHeight: '5px' }}>
            <Typography style={{ color: '#6C757D', fontSize: '.7rem', paddingBottom: '1px', marginBottom: '10px' }}>
                {subtitle}
            </Typography>

            <Typography variant="h5" style={{ color: '#223A59' }}>
                {title}
            </Typography>
        </Grid>
    );
};

export default SectionHeader;