import ModalWrapper from '../wrappers/ModalWrapper';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import InputBox from '../ui/InputBox';
import { validationTypeofPackageModal } from '../lib/validationSchema';

interface TypeofPackageModalProps {
    open: boolean;
    close: () => void;
    setAdditionalTypeOfPackage: (value: string) => void; 
    additionalTypeOfPackage: string; 
}

const TypeofPackageModal = ({
    open,
    close,
    setAdditionalTypeOfPackage,
    additionalTypeOfPackage,
}: TypeofPackageModalProps) => {
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setAdditionalTypeOfPackage(values.typeofpackage);
        setSubmitting(false); 
        resetForm();
        close(); 
    };

    const handleClose = (resetForm: () => void) => {
        resetForm();
        close();
    };


    return (
        <Formik
        initialValues={{ typeofpackage: additionalTypeOfPackage }} 
        onSubmit={handleSubmit}
        enableReinitialize={true} 
        validationSchema={validationTypeofPackageModal} 
    >
        {({ isSubmitting, handleChange, values, errors, touched, resetForm }) => (
            <ModalWrapper open={open} onClose={() => handleClose(resetForm)} heading={"Additional Type Of Package"}>
                <Form>
                    <InputBox
                        value={values.typeofpackage} 
                        onChange={handleChange} 
                        name="typeofpackage" 
                        type="text"
                        label="Type of package details"
                        error={errors.typeofpackage && touched.typeofpackage ? errors.typeofpackage : null}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting} 
                        style={{ margin: "20px 0" }}
                    >
                        Submit
                    </Button>
                </Form>
            </ModalWrapper>
        )}
    </Formik>
    );
};

export default TypeofPackageModal;
