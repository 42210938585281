import React,{memo} from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';

interface InputBoxProps {
  label: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  type?: string;
  value?: string | number;
  multiline?:boolean
  error?: any;
}

const InputBox: React.FC<InputBoxProps> = ({
  label,
  disabled = false,
  onChange,
  name,
  type = 'text',
  value,
  multiline=false,
  error
}) => {  
  return (
    <FormControl fullWidth variant="outlined" >
    <TextField
      id="outlined-basic"
      label={label}
      variant="outlined"
      disabled={disabled}
      onChange={onChange}
      name={name}
      type={type}
      multiline={multiline}
      value={value}
      fullWidth
      error={!!error}
      helperText={!!error}
    />
       <FormHelperText style={{ color: "#f44336" }}>{error}</FormHelperText>
       </FormControl>
  );
};

export default memo(InputBox);
