import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { routes } from '../../../routes';
import CarrierPages from '../../../ComponentsV2/pages/carrier/CarrierPages';
function CarrierManagement() {
  const match = useRouteMatch();
  return (
    <React.Fragment>
      <Route path={match.path + routes.home.carrier_management.all}>
        <CarrierPages />
      </Route>
    </React.Fragment>
  );
}
export default CarrierManagement;