import { useEffect, useState } from 'react';
import ModalWrapper from '../wrappers/ModalWrapper';
import { Box, Button, Typography } from '@material-ui/core';
import SelectorComp from '../ui/SelectorComp';
import { toast } from 'react-toastify';
import { WebStorage } from '../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../Types/Constants';
import ContainersTable from '../tables/ContainersTable';
import OthersTable from '../tables/OthersTable';

interface AssignJobModalProps {
    open: boolean;
    close: () => void;
    fetchJobs: () => void;
    assignedData?: any
}

const storage = new WebStorage();

    const AssignJobModal = ({ open, close, assignedData,fetchJobs }: AssignJobModalProps) => {
    const [selectedCareer, setSelectedCareer] = useState(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [careersData, setCareersData] = useState([]);

    useEffect(() => {
        getAllCarriersApi()
    }, [])
    const getAllCarriersApi = async () => {
        try {
            const myHeaders = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + `/v3/getAllCarriers`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();
            if (!result.carriers) { return }
            setCareersData(result.carriers || [])
        } catch (err) {
            console.error('Fetch error:', err);
        } 
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "job_id": assignedData?.id,
                "assigned_user_id": selectedCareer
            });

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(process.env.REACT_APP_NEW_BASE_URL +"/v3/assignJob", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if(result.status_code===201 && result.success===true){
                        toast.success("Job assigned to carrier successfully");
                        fetchJobs()
                        handleClose()
                    }                    
                })
                .catch((error) => console.error(error));
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoading(false);
        }
    }
    const handleCareer = (e) => {
        setSelectedCareer(e.target.value)
    }
    const handleClose = () => {
        if(!loading){
            setSelectedCareer(null)
            setLoading(false)
            close();
        }
    };

    if (!assignedData) {
        return (
            <ModalWrapper open={open} onClose={handleClose} heading="Assign Job">
                <Box padding={2}>
                    <Typography variant="body1">No data available to display.</Typography>
                </Box>
            </ModalWrapper>
        );
    }

    const formattedCareerTypes = careersData?.map(career => ({
        value: career.id,
        label: career.accountcontactname
    }));

    const isContainer = assignedData?.movement_type === "containers"
console.log(assignedData);

    return (
        <ModalWrapper open={open} onClose={handleClose} heading="Assign Job">

            <form onSubmit={handleSubmit}>
                <SelectorComp
                    label="Carriers"
                    onChange={handleCareer}
                    options={formattedCareerTypes}
                    value={selectedCareer}
                    name="groupType"
                />

                <Button
                    type="submit"
                    fullWidth
                    color="secondary"
                    variant="contained"
                    style={{ margin: '20px 0' }}
                    disabled={!selectedCareer||loading}
                >
                    Submit
                </Button>
            </form>

            {isContainer ? <ContainersTable data={assignedData} /> : <OthersTable data={assignedData} />}


        </ModalWrapper>
    );
};

export default AssignJobModal;
