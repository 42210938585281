import PlacesAutocomplete, { getLatLng, geocodeByPlaceId, Suggestion } from 'react-places-autocomplete';
import { TextField, MenuList, MenuItem, Popover, InputAdornment } from '@material-ui/core'
import EditLocationRoundedIcon from '@material-ui/icons/EditLocationRounded';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MapAddress from '../../../BP/MapAddress';
import { toast } from 'react-toastify';
import { IMapAutocompleteProps } from './MapAutocomplete.types';

function MapAutocomplete(props: IMapAutocompleteProps) {
    const [t] = useTranslation('MapAutocomplete')
    const [address, setAddress] = useState('')

    const anchorRef = useRef(null)

    const handleClose = () => {
    }

    const handleMenuItemSelect = (suggestion: Suggestion) => {
        setAddress(suggestion.description)
        geocodeByPlaceId(suggestion.placeId)
            .then((results) => {
                const result = results[0]

                if (!result) {
                    toast.warn(t('warn_getting_address'))
                    return
                }

                getLatLng(result)
                    .then((lat_long) => {
                        if (props.onAddressSelect) props.onAddressSelect(MapAddress.parseMapResult(result, lat_long))
                    })
                    .catch((err :any) => {
                        console.log('err', err)
                        toast.error(t('error_getting_address_coords'))
                    })
            })
            .catch((err :any) => {
                console.log('err', err)
                toast.error(t('error_getting_address_details'))
            })
    }

    return (
        <PlacesAutocomplete
            value={address}
            onChange={(address) => setAddress(address)}
        >
            {
                ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                    return (
                        <>
                            <TextField
                                {...getInputProps()}
                                ref={anchorRef}
                                variant="outlined"
                                fullWidth
                                label={t('search_address')}
                                disabled={props.readOnly}
                                InputProps={{
                                    endAdornment: loading ? (
                                        <InputAdornment position="end">
                                            <CircularProgress disableShrink />
                                        </InputAdornment>
                                    ) : null
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                            <Popover
                                open={!loading && suggestions.length > 0}
                                anchorEl={anchorRef.current}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                disableAutoFocus
                                disableEnforceFocus
                            >
                                <MenuList style={{ width: anchorRef.current ? (anchorRef.current as any).clientWidth : 0, maxHeight: 500, overflowY: 'auto' }}>
                                    {suggestions.map((suggestion, i) => <MenuItem {...getSuggestionItemProps(suggestion)} key={i} onClick={() => handleMenuItemSelect(suggestion)}>{suggestion.description}</MenuItem>)}
                                </MenuList>
                            </Popover>
                        </>
                    )
                }

            }
        </PlacesAutocomplete>
    )
}

export default MapAutocomplete