import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

interface CustomSearchInputProps {
  label?: string;
  onSearch: (query: string) => void;
  value: string;
  variant?: 'outlined' | 'filled' | 'standard';
}

const CustomSearchInput: React.FC<CustomSearchInputProps> = ({
  label,
  onSearch,
  value,
  variant,
}) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <TextField
      label={label || 'Search'}
      value={value}
      onChange={handleChange}
      variant={variant}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onSearch(value)}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        style: {
          fontWeight: 'bold',
        },
      }}
    />
  );
};

export default CustomSearchInput;
