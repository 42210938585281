import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import CustomSearchInput from '../../ui/CustomSearchInput';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import AssignJobModal from '../../modals/AssignJobModal';
import useDebounce from '../../hooks/useDebounce';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Redux';

export type AdminModalsState = {
    modal1: boolean;
};

const useStyles = makeStyles(() => ({
    root: {
        height: 700,
        width: '100%',
        overflowX: 'auto',
    },
    dataGrid: {
        minWidth: "fit-content",
        '& .MuiDataGrid-menuIconButton': {
            color: 'white',
        },
    },
    search: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "20px 0",
    },
    errorMessage: {
        color: 'red',
        margin: '20px 0',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    }
}));

const storage = new WebStorage();

const JobsToBeAssignedToCarrier = (props: any) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [assignedData, setAssignedData] = useState(null);
    const [search, setSearch] = useState("");
    const user = useSelector((state: AppState) => state.user.response);
    
    const [modals, setModals] = useState<AdminModalsState>({
        modal1: false
    });
    const [data, setData] = useState({
        loading: true,
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 20],
        page: 1,
        pageSize: 10,
        rows: [],
    });

    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchJobs();
    }, [debouncedSearch]);

    const fetchJobs = async () => {
        setLoading(true);
        try {
            const myHeaders = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });
            const filters = { reference: debouncedSearch };
            const encodedFilters = encodeURIComponent(JSON.stringify(filters)); 

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            const response = await fetch(process.env.REACT_APP_API_URL +`/job/get/carriers/job?assigned_user_id=${user?.id}&search=${encodedFilters}`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();

            const formattedRows = result?.jobs?.map((job: any) => ({
                id: job.id,
                recipient_name: job.recipient_name,
                trip_type: job.trip_type,
                vessel_name: job.vessel_name,
                good_description: job.good_description,
                job_instructions: job.job_instructions,
                shipment_no: job.shipment_no,
                status: job.status,
                created_at: job.created_at?.split("T")[0],
            }));

            setData((prevData) => ({
                ...prevData,
                rows: formattedRows,
                totalRows: result.jobs?.length,
            }));
        } catch (err) {
            console.error('Fetch error:', err);
        } finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = useMemo(() => [
        { field: 'shipment_no', headerName: 'Shipment No', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'recipient_name', headerName: 'Recipient Name', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'trip_type', headerName: 'Trip Type', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'vessel_name', headerName: 'Vessel Name', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'good_description', headerName: 'Good Description', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'job_instructions', headerName: 'Job Instructions', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'status', headerName: 'Status', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'created_at', headerName: 'created At', minWidth: 180, align: 'center', headerAlign: 'center' },
        // { field: 'assign', headerName: 'Assign', minWidth: 180, align: 'center', headerAlign: 'center', sortable: false,
        //     renderCell: (params) => (
        //         <div style={{ display: 'flex', gap: '10px' }}>
        //             <button
        //                 style={{ backgroundColor: '#1976d2', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
        //                 onClick={() => { toggleModal("modal1"); setAssignedData(params.row); }}
        //             >
        //                 Assign
        //             </button>
        //         </div>
        //     )},
    ], []);

    const toggleModal = useCallback(
        (modalName: keyof AdminModalsState) => {
            setModals((prevState) => ({
                ...prevState,
                [modalName]: !prevState[modalName],
            }));
        },
        [setModals]
    );

    const handleSearch = (query: string) => {        
        setSearch(query);
    };

    return (
        <>
            <AssignJobModal
                open={modals.modal1}
                close={() => toggleModal("modal1")}
                assignedData={assignedData} fetchJobs={function (): void {
                    throw new Error('Function not implemented.');
                } }            />
            <SectionHeader subtitle="Transport Management" title="Assigned Jobs" />
            <div className={classes.search}>
                <CustomSearchInput
                    label="Please enter job reference"
                    onSearch={handleSearch}
                    variant="outlined"
                    value={search}
                />
            </div>
            {data?.rows?.length === 0 && !loading && <div className={classes.errorMessage}>No jobs found.</div>}

            <div className={classes.root}>
                <DataGrid
                    className={classes.dataGrid}
                    onRowClick={props.onRowClick}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={{ noRowsLabel: 'No records found' }}
                    paginationMode="client"
                    sortingMode="client"
                    loading={loading}
                    rowCount={data.totalRows}
                    rowsPerPageOptions={data.rowsPerPageOptions}
                    page={data.page - 1}
                    pageSize={data.pageSize}
                    rows={data.rows}
                    onPageChange={(newPage) => { setData((prev) => ({ ...prev, page: newPage + 1 })); }}
                    onPageSizeChange={(newPageSize) => {
                        setData((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }));
                    }}
                />
            </div>
        </>
    );
}


export default JobsToBeAssignedToCarrier