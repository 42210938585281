import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import classes from "../../styles/createjobv2.module.css"
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useDropzone } from 'react-dropzone'
import { Formik, Form, FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { ADDRESS_GROUPS, AU_STATES, COUNTRIES, DOCS, movementTypeOptions, NZ_STATES, packageTypeOptions, PALLET_ACCOUNT_ACTION, PALLET_ACCOUNT_TYPE, POST_TO, SERVICE_TYPE, TEMPERATURE_TYPE, tripOptions, USER_ROLES } from '../../lib/constants';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Customer } from '../../../Api/Customer/Customer';
import EditAddressModal from '../../modals/EditAddressModal';
import { CreateJobModalsState } from '../../../Types/global';
import CreateAddressModal from '../../modals/CreateAddressModal';
import { IAddress } from '../../../../../Application/DTOs/AddressDto/AddressDto.types';
import { Job } from '../../../Api/Job/Job';
import { Address } from '../../../Api/Address/Address';
import { ICompanyInfo } from '../../../../../Application/DTOs/CompanyDto/CompanyDto.type';
import { AppState } from '../../../Redux';
import CreateJobTable from '../../tables/CreateJobTable';
import CustomTimePicker from '../../ui/CustomTimePicker';
import InputBox from '../../ui/InputBox';
import SelectorComp from '../../ui/SelectorComp';
import MultiSelectDropDownComp from '../../ui/MultiSelectDropDownComp';
import AddContainerModal from '../../modals/AddContainerModal';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import { v4 as uuidv4 } from 'uuid';
import JobTypeModal from '../../modals/JobTypeModal';
import TypeofPackageModal from '../../modals/TypeofPackageModal';
import CustomDatePickerNew from '../../ui/CustomDatePickerNew';
import { useParams } from 'react-router-dom';
import { validationCreateJobSchema } from '../../lib/validationSchema';
import { checkIsEmpty } from '../../lib/helper';

const jobApi = new Job();
const addressService = new Address();
const customerApi = new Customer()
const storage = new WebStorage();

interface SelectedOptions {
    id: string;
    index: string
    state: string
}

type DocType = typeof DOCS[number]['value'];

const EditJobV2 = () => {
    const { id } = useParams<{ id: string }>();
    const [t] = useTranslation();
    const { ADMIN_USER, SUPER_ADMIN } = USER_ROLES;
    const user = useSelector((state: AppState) => state.user.response);
    const isUserAdmin = user?.type === ADMIN_USER;
    const isSuperAdmin = user?.type === SUPER_ADMIN;
    const countryInitial = isSuperAdmin || isUserAdmin ? "australia" : user?.company?.country || "australia";
    const companyDropdownData = useSelector((state: AppState) => state.company);
    const [dataSet, setDataset] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedMovementType, setSelectedMovementType] = useState<string | unknown>("");
    const [addresses, setAddresses] = useState([]);
    const [showContainerTable, setShowContainerTable] = useState<boolean>(false)
    const [selectedPickUpIds, setSelectedPickUpIds] = useState<SelectedOptions[]>([]);
    const [selectedDeliverIds, setSelectedDeliverIds] = useState<SelectedOptions[]>([]);
    const [customerOptions, setCustomerOptions] = useState<string[]>([]);
    const [addressId, setAddressId] = useState<string>("");
    const [toggleTemp, setToggleTemp] = useState<boolean>(false)
    const [editingContainer, setEditingContainer] = useState(null);
    const [additionalJobType, setAdditionalJobType] = useState<string>("");
    const [additionalTypeOfPackage, setAdditionalTypeOfPackage] = useState<string>("");
    const [initialValuesOld, setInitialValuesOld] = useState({ jobContainers: [], });
    const [modals, setModals] = useState<CreateJobModalsState>({
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,   //add job type
        modal5: false   //add type of package
    });
    const [docType, setDocType] = useState<DocType | ''>('');
    const [file, setFile] = useState<File | null>(null);
    const [fileContainer, setFileContainer] = useState<File | null>(null);
    const [docTypeContainer, setDocTypeContainer] = useState<DocType | ''>('');
    const [job, setJob] = useState(null)
    const [uploadedFiles, setUploadedFiles] = useState<Record<DocType, File | null>>(
        DOCS.reduce((acc, doc) => {
            acc[doc.value as DocType] = null;
            return acc;
        }, {} as Record<DocType, File | null>)
    );

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
    });

    const handleAddDocument = () => {
        if (docType && file) {
            setUploadedFiles((prevFiles) => ({
                ...prevFiles,
                [docType]: file,
            }));
            setDocType('');
            setFile(null);
        } else {
            toast.error('Please select a document type and upload a file.');
        }
    };
    const handleDeleteFile = (type: DocType) => {
        setUploadedFiles((prevFiles) => ({
            ...prevFiles,
            [type]: null,
        }));
    };

    useEffect(() => {
        fetchJob()
        fetchData();
        fetchAddresses();
    }, [id]);


    const fetchJob = async () => {
        setLoading(true)
        try {
            const myHeaders = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });
            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + `/v1/job/detail/${id}`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();            
            setJob(result)
            setInitialValuesOld({ jobContainers: result?.job_containers })
            setSelectedMovementType(result?.movement_type?.code)
            result?.job_files?.forEach((item) => {
                const docType = DOCS.find(doc => doc.value?.replaceAll(' ', '_')?.toLowerCase() === item?.type?.toLowerCase());
                if (docType) {
                    setUploadedFiles((prevFiles) => ({
                        ...prevFiles,
                        [docType.value]: new File([item.modified_file_name], item.original_file_name)
                    }));
                }
            });
        } catch (err) {
            console.error('Fetch error:', err);
        }
        finally {
            setLoading(false)
        }
    };

    const fetchData = async () => {
        const cachedOptions = localStorage.getItem('transOptions');
        if (cachedOptions) {
            setDataset(JSON.parse(cachedOptions));
            setLoading(false);
        } else {
            try {
                const response = await jobApi.getTransportMapping();
                setDataset(response.data);
                localStorage.setItem('transOptions', JSON.stringify(response.data));
            } catch (err) {
                console.error('Failed to fetch transport mapping:', err);
            } finally {
                setLoading(false);
            }
        }
    };

    const fetchAddresses = async () => {
        try {
            const companyId = 184;
            const response = await addressService.allForJob(
                companyId,
                true,
                countryInitial,
                false,
                false,
                true
            );
            setAddresses(response.data as IAddress[]);
        } catch (err) {
            toast.error(t('error_getting_addresses'));
        }
    };

    const getCustomerPerCompany = (search_id: any) => {
        customerApi.getAll(0, 99999, isUserAdmin, undefined, search_id, true)
            .then((response) => {
                const rows = response.data.rows;
                const count = response.data.count;
                setCustomerOptions(rows)
                return { jobs: rows, count }
            })
    }

    const initialValues = {
        company: job?.company_id || '',
        movementType: job?.movement_type?.code || '',
        shipmentNo: job?.shipment_no || '',
        customer: '6',
        jobType: job?.movement_type?.code==="oversized_items"?job?.other_job_type: (job?.job_type_id || ''),
        m141: job?.reference || '',
        customerReference: job?.customer_reference || '',
        tripType: job?.trip_type || '',
        transportType: job?.transport_type || '',
        temperature: job?.temperature || '',
        temperatureDetail: job?.temperature_instructions || '',
        groupType: "",
        goodsDesc: job?.good_description || "",
        totalQty: job?.total_qty || "",
        netWeight: job?.total_net_weight || "",
        palletAcc: job?.pallet_account_type || "",
        palletAccNumber: job?.pallet_account_number || "",
        grossWeight: job?.total_gross_weight || "",
        volume: job?.volume || "",
        palletAccAction: "",
        containerNo: "",
        wharfSlot: "",
        sealNo: "",
        cntrlfd: "",
        ecSealNo: "",
        declaredGrossWeight: "",
        document: "",
        serviceType: job?.service_type || "",
        jobServiceCharges: "",
        postTo: job?.post_to || "",
        buyPrice: job?.buy_price || "",
        sellPrice: job?.sell_price || "",
        jobInstruction: job?.job_instructions || "",
        containerOverwight: false,
        jobDetails: job?.new_job_details
            ? job?.new_job_details?.map((detail) => ({
                jobDetailPackage: detail?.job_detail_package || '',
                jobDetailQty: detail?.job_detail_qty || '',
                jobDetailSKU: detail?.job_detail_sku || '',
                jobDetailDesc: detail?.job_detail_desc || '',
                jobDetailLot: detail?.job_detail_lot || '',
                jobDetailHeight: detail?.job_detail_height || '',
                jobDetailWidth: detail?.job_detail_width || '',
                jobDetailLength: detail?.job_detail_length || '',
                jobDetailNetWeight: detail?.job_detail_net_weight || '',
            }))
            : [
                {
                    jobDetailPackage: '',
                    jobDetailQty: '',
                    jobDetailSKU: '',
                    jobDetailDesc: '',
                    jobDetailLot: '',
                    jobDetailHeight: '',
                    jobDetailWidth: '',
                    jobDetailLength: '',
                    jobDetailNetWeight: '',
                },
            ],
        jobDetailVesselName: job?.vessel_name || "",
        jobDetailETA: job?.vessel_eta || "",
        jobDetailVoyage: job?.voyage || "",
        trip: job?.job_addresses
            ? job?.job_addresses?.map((item) => ({
                tripPickVehicleType: item?.pickup_vehicle_type || '',
                tripPickGroupType: item?.pickup_group || '',
                tripPickCountry: item?.pickupAddress?.country || '',
                tripPickState: item?.pickupAddress?.state || '',
                tripPickShipId: item?.pickup_address_id || '',
                tripPickAddress: item?.pickup_address_id || '',
                tripPickDate: item?.pickup_date_time || '',
                tripPickTime: item?.pickup_date_time?.split('T')[1]?.slice(0, 5) || '',
                tripPickJobRequirement: item?.pickup_job_requirement || '',
                tripDeliverVehicleType: item?.delivery_vehicle_type || '',
                tripDeliverGroupType: item?.delivery_group || '',
                tripDeliverCounty: item?.deliveryAddress?.country || '',
                tripDeliverState: item?.deliveryAddress?.state || '',
                tripDeliverShipId: item?.delivery_address_id || '',
                tripDeliverAddress: item?.delivery_address_id || '',
                tripDeliverDate: item?.delivery_date_time || '',
                tripDeliverTime: item?.delivery_date_time?.split('T')[1]?.slice(0, 5) || '',
                tripDeliverJobRequirement: item?.delivery_job_requirement || '',
            }))
            : [
                {
                    tripPickVehicleType: '',
                    tripPickGroupType: '',
                    tripPickCountry: countryInitial,
                    tripPickState: '',
                    tripPickShipId: '',
                    tripPickAddress: '',
                    tripPickDate: "",
                    tripPickTime: '',
                    tripPickJobRequirement: '',
                    tripDeliverVehicleType: '',
                    tripDeliverGroupType: "",
                    tripDeliverCounty: countryInitial,
                    tripDeliverState: "",
                    tripDeliverShipId: "",
                    tripDeliverAddress: "",
                    tripDeliverDate: "",
                    tripDeliverTime: "",
                    tripDeliverJobRequirement: "",
                },
            ],
    };

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        const myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`);

        const jobData: any = {
            company_id: checkIsEmpty(values.company),
            movement_type: checkIsEmpty(values.movementType),
            shipment_no: checkIsEmpty(values.shipmentNo),
            customer_id: checkIsEmpty(values.customer_id),
            job_type_id: selectedMovementType === "oversized_items" ? 11 : checkIsEmpty(values.jobType),
            reference: checkIsEmpty(job?.reference),
            customer_reference: checkIsEmpty(job?.customerReference),
            trip_type: checkIsEmpty(values.tripType),
            transport_type: checkIsEmpty(values.transportType),
            service_type: checkIsEmpty(values.serviceType),
            post_to: checkIsEmpty(values.postTo),
            buy_price: checkIsEmpty(values.buyPrice),
            cell_price: checkIsEmpty(values.sellPrice),
            job_instructions: checkIsEmpty(values.jobInstruction),
            other_job_type: selectedMovementType === "oversized_items" ? checkIsEmpty(values.jobType) : checkIsEmpty(additionalJobType),
        };

        const requestBody: any = {

            jobData,
            trip: values.trip.map((trip: any) => ({
                pickup_address_id: checkIsEmpty(trip.tripPickShipId),
                delivery_address_id: checkIsEmpty(trip.tripDeliverShipId),
                pickup_date_time: checkIsEmpty(`${trip.tripPickDate}T${trip.tripPickTime}`),
                delivery_date_time: checkIsEmpty(`${trip.tripDeliverDate}T${trip.tripDeliverTime}`),
                pickup_job_requirement: checkIsEmpty(trip.tripPickJobRequirement),
                delivery_job_requirement: checkIsEmpty(trip.tripDeliverJobRequirement),
                delivery_vehicle_type: checkIsEmpty(trip.tripDeliverVehicleType),
                pickup_vehicle_type: checkIsEmpty(trip.tripPickVehicleType),
                pickup_group: checkIsEmpty(trip.tripPickGroupType),
                delivery_group: checkIsEmpty(trip.tripDeliverGroupType),
            })),
        };

        if (toggleTemp && selectedMovementType !== "containers") {
            requestBody.jobData.temperature = checkIsEmpty(values.temperature);
            requestBody.jobData.volume = checkIsEmpty(values.volume);
            requestBody.jobData.total_qty = checkIsEmpty(values.totalQty);
            requestBody.jobData.good_description = checkIsEmpty(values.goodsDesc);
            requestBody.jobData.total_net_weight = checkIsEmpty(values.netWeight);
            requestBody.jobData.pallet_account_type = checkIsEmpty(values.palletAcc);
            requestBody.jobData.total_gross_weight = checkIsEmpty(values.grossWeight);
            requestBody.jobData.pallet_account_number = checkIsEmpty(values.palletAccNumber);
            requestBody.jobData.temperature_instructions = checkIsEmpty(values.temperatureDetail);
        } else if (selectedMovementType !== "containers") {
            requestBody.jobData.volume = checkIsEmpty(values.volume);
            requestBody.jobData.total_qty = checkIsEmpty(values.totalQty);
            requestBody.jobData.good_description = checkIsEmpty(values.goodsDesc);
            requestBody.jobData.total_net_weight = checkIsEmpty(values.netWeight);
            requestBody.jobData.pallet_account_type = checkIsEmpty(values.palletAcc);
            requestBody.jobData.total_gross_weight = checkIsEmpty(values.grossWeight);
            requestBody.jobData.pallet_account_number = checkIsEmpty(values.palletAccNumber);
        }

        if (selectedMovementType === "containers") {
            if (initialValuesOld?.jobContainers?.length > 0) {
                requestBody.jobContainers = initialValuesOld.jobContainers.map(({ id, ...container }) => container);
            }

            requestBody.jobData.vessel_name = checkIsEmpty(values.jobDetailVesselName);
            requestBody.jobData.vessel_eta = checkIsEmpty(values.jobDetailETA);
            requestBody.jobData.voyoge = checkIsEmpty(values.jobDetailVoyage);
        } else {
            requestBody.jobDetails = values.jobDetails.map((detail: any) => ({
                job_detail_package: additionalTypeOfPackage ? checkIsEmpty(additionalTypeOfPackage) : checkIsEmpty(detail.jobDetailPackage),
                job_detail_qty: checkIsEmpty(detail.jobDetailQty),
                job_detail_sku: checkIsEmpty(detail.jobDetailSKU),
                job_detail_desc: checkIsEmpty(detail.jobDetailDesc),
                job_detail_lot: checkIsEmpty(detail.jobDetailLot),
                job_detail_height: checkIsEmpty(detail.jobDetailHeight),
                job_detail_width: checkIsEmpty(detail.jobDetailWidth),
                job_detail_length: checkIsEmpty(detail.jobDetailLength),
                job_detail_net_weight: checkIsEmpty(detail.jobDetailNetWeight),
            }));
        }

        // Create FormData and append each part as stringified JSON
        const formData = new FormData();
        formData.append('jobData', JSON.stringify(requestBody.jobData));
        formData.append('trip', JSON.stringify(requestBody.trip));
        formData.append('jobId', id);
        if (requestBody.jobContainers) {
            formData.append(
                'jobContainers',
                JSON.stringify(requestBody.jobContainers)
            );
        }
        if (requestBody.jobDetails) {
            formData.append('jobDetails', JSON.stringify(requestBody.jobDetails));
        }
        // Append files from the 'uploadedFiles' object
        Object.entries(uploadedFiles).forEach(([key, file]) => {
            if (file) {
                formData.append(key, file);
            } else {
                formData.append(key, "");
            }
        });

        // const raw = JSON.stringify(requestBody);

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow"
        };

        try {
            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/v3/createNewJob2", requestOptions);

            const result = await response.json();
            if (result.status_code === 201 && result.success === true) {
                fetchJob()
                toast.success("Job edited successfully");
                // setShowContainerTable(false)
                // setToggleTemp(false)
                // setSelectedPickUpIds([])
                // setSelectedDeliverIds([])
                // setAdditionalJobType("")
                // setAdditionalTypeOfPackage("")
                // setSelectedMovementType("")
                // setUploadedFiles(DOCS.reduce((acc, doc) => {
                //     acc[doc.value as DocType] = null;
                //     return acc;
                // }, {} as Record<DocType, File | null>));
                // resetForm()
            }
            else if (result.status_code === 400 && result.success === false) {
                toast.error(result.message);
            }
        } catch (error) {
            console.error(error);
            toast.error(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    const toggleModal = (modalName: keyof CreateJobModalsState) => {
        setModals((prevState) => ({
            ...prevState,
            [modalName]: !prevState[modalName],
        }));
    }

    const handleButtonClick = (id: string) => {
        setAddressId(id);
    };
    const addJobContainer = (newContainer) => {
        setInitialValuesOld((prev) => {
            const updatedContainer = newContainer.id
                ? newContainer
                : { ...newContainer, id: uuidv4() };

            const containerIndex = prev.jobContainers.findIndex(
                (container) => container.id === updatedContainer.id
            );

            const updatedContainers = containerIndex > -1
                ? prev.jobContainers.map((container, index) =>
                    index === containerIndex ? updatedContainer : container
                )
                : [...prev.jobContainers, updatedContainer];

            return { ...prev, jobContainers: updatedContainers };
        });
        setEditingContainer(null);
    };

    const handleEditContainer = (container) => {
        setEditingContainer(container);
        toggleModal("modal3");
    };

    const handleDeleteContainer = (containerId) => {
        setInitialValuesOld((prev) => ({
            ...prev,
            jobContainers: prev.jobContainers.filter((c) => c.id !== containerId),
        }));
    };

    const isSelected = selectedMovementType ? selectedMovementType : job?.movement_type?.code;
    const selectedMovementData = dataSet?.find(movement => movement.movementType.code === isSelected) ?? null;
    const jobTypesData = selectedMovementData ? selectedMovementData.jobTypes : [];
    const vehicleTypes = selectedMovementData ? selectedMovementData.vehicleTypes : [];
    const formattedJobTypes = jobTypesData?.map(job => ({
        value: job.id,
        label: job.code?.charAt(0)?.toUpperCase() + job.code?.slice(1)?.replaceAll("_", " ")
    }));

    const formattedVehiclesTypes = vehicleTypes?.map(vehicle => ({
        value: vehicle.id,
        label: vehicle.code?.charAt(0)?.toUpperCase() + vehicle.code?.slice(1)?.replaceAll("_", " ")
    }));


    const handleDocTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDocType(event.target.value as DocType);
    };

    const handleCreateAddressModal = (isSaved = false) => {
        toggleModal("modal1")
        if (isSaved) {
            fetchAddresses();
        }
    }


    const isContainer = job?.job_containers?.length > 0 ? true : showContainerTable
    return (
        <>
            <JobTypeModal
                close={() => toggleModal("modal4")}
                open={modals.modal4}
                setAdditionalJobType={setAdditionalJobType}
                additionalJobType={additionalJobType}
            />
            <TypeofPackageModal
                close={() => toggleModal("modal5")}
                open={modals.modal5}
                setAdditionalTypeOfPackage={setAdditionalTypeOfPackage}
                additionalTypeOfPackage={additionalTypeOfPackage}
            />
            <CreateAddressModal
                close={handleCreateAddressModal}
                open={modals.modal1}
                fetchAddresses={fetchAddresses}
            />
            <EditAddressModal
                close={() => toggleModal("modal2")}
                open={modals.modal2}
                addressId={addressId}
                addresses={addresses}
                fetchAddresses={fetchAddresses}

            />
            <AddContainerModal
                close={() => toggleModal("modal3")}
                open={modals.modal3}
                onAddJobContainer={addJobContainer}
                initialValues={editingContainer}
                setFileContainer={setFileContainer}
                fileContainer={fileContainer}
                docTypeContainer={docTypeContainer}
                setDocTypeContainer={setDocTypeContainer}
            />
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationCreateJobSchema}
            >
                {({ isSubmitting, handleChange, setFieldValue, values, errors, touched }) => (
                    <Form className={classes.formContainer}>
                        <Grid item md={3} container direction="column" alignItems="flex-start" style={{ lineHeight: '5px' }}>
                            <Typography style={{ color: '#6C757D', fontSize: '.7rem', paddingBottom: '1px', marginBottom: '10px' }}>
                                {t('transport_management')}
                            </Typography>

                            <Typography variant="h5" style={{ color: '#223A59' }}>
                                Edit Job
                            </Typography>
                        </Grid>
                        <div className={classes.line}></div>

                        <div className={classes.jobcontainer}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <MultiSelectDropDownComp<ICompanyInfo>
                                        options={companyDropdownData.list}
                                        label="Company"
                                        name="company"
                                        value={values.company}
                                        getOptionLabel={(option) => option.companyName}
                                        getOptionId={(option) => option.id}
                                        onChange={(event, selectedOption) => {
                                            const id = selectedOption ? selectedOption.id : ""
                                            setFieldValue("company", id);
                                            getCustomerPerCompany(id)
                                        }}
                                        disabled
                                        error={errors.company && touched.company ? errors.company : null}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectorComp
                                        label="Movement Type"
                                        name="movementType"
                                        value={values.movementType}
                                        onChange={(event) => {
                                            const newValue = event.target.value;
                                            setFieldValue("movementType", newValue);
                                            setSelectedMovementType(newValue)
                                        }}
                                        disabled
                                        options={movementTypeOptions}
                                        error={errors.movementType && touched.movementType ? errors.movementType : null}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputBox
                                        label="Shipment Number"
                                        value={values.shipmentNo}
                                        onChange={handleChange}
                                        name="shipmentNo"
                                        type="text"
                                        disabled={false}
                                        error={errors.shipmentNo && touched.shipmentNo ? errors.shipmentNo : null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <MultiSelectDropDownComp<any>
                                        options={customerOptions}
                                        label="Customer"
                                        name="customer"
                                        value={values.customer}
                                        getOptionLabel={(option) => option['company_name'] || ''}
                                        getOptionId={(option) => option['user']['company_id']}
                                        onChange={(event, selectedOption) => {
                                            const id = selectedOption ? selectedOption['user']['company_id'] : "";
                                            setFieldValue("customer", id);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    {selectedMovementType === "oversized_items" ?
                                        <InputBox
                                            label="Job Type"
                                            value={values.jobType}
                                            onChange={handleChange}
                                            name="jobType"
                                            type="text"
                                            error={errors.jobType && touched.jobType ? errors.jobType : null}
                                        />
                                        :
                                        <SelectorComp
                                            label="Job Type"
                                            name="jobType"
                                            value={values.jobType}
                                            options={formattedJobTypes}
                                            error={errors.jobType && touched.jobType ? errors.jobType : null}
                                            onChange={(event) => {
                                                const newValue = event.target.value;
                                                setFieldValue("jobType", newValue);
                                                toggleModal(newValue === 11 ? "modal4" : null)
                                            }}
                                        />
                                    }

                                </Grid>
                                <Grid item xs={4}>
                                    <InputBox
                                        label="MI4U Reference Number"
                                        value={values.m141}
                                        onChange={handleChange}
                                        name="m141"
                                        type="text"
                                        disabled={true}
                                        error={errors.m141 && touched.m141 ? errors.m141 : null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <InputBox
                                        label="Customer Reference Number"
                                        value={values.customerReference}
                                        onChange={handleChange}
                                        name="customerReference"
                                        type="text"
                                        disabled={false}
                                        error={errors.customerReference && touched.customerReference ? errors.customerReference : null}

                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectorComp
                                        label="Trip Type"
                                        options={tripOptions}
                                        name="tripType"
                                        value={values.tripType}
                                        onChange={handleChange}
                                        error={errors.tripType && touched.tripType ? errors.tripType : null}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectorComp
                                        label="Transport Type"
                                        options={formattedVehiclesTypes}
                                        name="transportType"
                                        value={values.transportType}
                                        onChange={handleChange}
                                        error={errors.transportType && touched.transportType ? errors.transportType : null}

                                    />
                                </Grid>
                            </Grid>

                            {/* trip section  start*/}
                            <Grid container spacing={3}>
                                <FieldArray name="trip">
                                    {({ insert, remove, push }) => (
                                        <>
                                            {values.trip.length > 0 && values.trip.map((item, index) => {
                                                return < >
                                                    {/* pickup from */}
                                                    <Grid item xs={6} key={index}>
                                                        <div className={classes.line}></div>
                                                        <Typography
                                                            variant="body1"
                                                            gutterBottom
                                                        >
                                                            TRIP {index + 1}-PICK UP FROM
                                                        </Typography>
                                                        <div className={classes.flexWrapper}>
                                                            <SelectorComp
                                                                label="Vehicle Type"
                                                                value={item.tripPickVehicleType}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setFieldValue(`trip.${index}.tripPickVehicleType`, value);
                                                                    setFieldValue(`trip.${index}.tripDeliverVehicleType`, value);
                                                                }}
                                                                options={formattedVehiclesTypes}
                                                                name={`trip.${index}.tripPickVehicleType`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickVehicleType && touched.trip && touched.trip[index] && touched.trip[index].tripPickVehicleType
                                                                    ? errors.trip[index].tripPickVehicleType
                                                                    : null}
                                                            />
                                                            <SelectorComp
                                                                label="Groups"
                                                                value={item.tripPickGroupType}
                                                                onChange={handleChange}
                                                                options={ADDRESS_GROUPS}
                                                                name={`trip.${index}.tripPickGroupType`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickGroupType && touched.trip && touched.trip[index] && touched.trip[index].tripPickGroupType
                                                                    ? errors.trip[index].tripPickGroupType
                                                                    : null}
                                                            />
                                                        </div>
                                                        <div className={classes.flexWrapper}>
                                                            <SelectorComp
                                                                label="Country"
                                                                value={item.tripPickCountry}
                                                                onChange={handleChange}
                                                                options={COUNTRIES}
                                                                name={`trip.${index}.tripPickCountry`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickCountry && touched.trip && touched.trip[index] && touched.trip[index].tripPickCountry
                                                                    ? errors.trip[index].tripPickCountry
                                                                    : null}
                                                            />
                                                            <SelectorComp
                                                                label="State"
                                                                value={item.tripPickState}
                                                                onChange={handleChange}
                                                                options={item.tripPickCountry === "australia" ? AU_STATES : item.tripPickCountry === "new_zealand" ? NZ_STATES : []}
                                                                name={`trip.${index}.tripPickState`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickState && touched.trip && touched.trip[index] && touched.trip[index].tripPickState
                                                                    ? errors.trip[index].tripPickState
                                                                    : null}
                                                            />
                                                        </div>

                                                        <div className={classes.flexWrapper}>
                                                            <MultiSelectDropDownComp<any>
                                                                options={values.company && item.tripPickState ? addresses.filter((address) => address.state === item.tripPickState) : []}
                                                                label="Ship ID"
                                                                name={`trip.${index}.tripPickShipId`}
                                                                value={item.tripPickShipId}
                                                                getOptionLabel={(option) => option.id.toString()}
                                                                getOptionId={(option) => option.id}
                                                                onChange={(event, selectedOption) => {
                                                                    const newSelectedOptions = [...selectedPickUpIds];
                                                                    newSelectedOptions[index] = {
                                                                        ...selectedOption,
                                                                        index: index
                                                                    };
                                                                    setSelectedPickUpIds(newSelectedOptions);
                                                                    setFieldValue(`trip.${index}.tripPickShipId`, selectedOption ? selectedOption.id : "");
                                                                    setFieldValue(`trip.${index}.tripPickAddress`, selectedOption ? selectedOption.id : "");
                                                                }}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickShipId && touched.trip && touched.trip[index] && touched.trip[index].tripPickShipId
                                                                    ? errors.trip[index].tripPickShipId
                                                                    : null}
                                                            />
                                                            <MultiSelectDropDownComp
                                                                options={values.company && item.tripPickState ? addresses.filter((address) => address.state === item.tripPickState) : []}
                                                                label="Address"
                                                                name={`trip.${index}.tripPickAddress`}
                                                                value={item.tripPickShipId}
                                                                getOptionLabel={(option) => `${option.company_name} - ${option.street_number}, ${option.city}, ${option.state}, ${option.postcode}`}
                                                                getOptionId={(option) => option.id}
                                                                onChange={(event, selectedOption) => {
                                                                    const newSelectedOptions = [...selectedPickUpIds];
                                                                    newSelectedOptions[index] = {
                                                                        ...selectedOption,
                                                                        index: index
                                                                    };
                                                                    setSelectedPickUpIds(newSelectedOptions);
                                                                    setFieldValue(`trip.${index}.tripPickShipId`, selectedOption ? selectedOption.id : "");
                                                                    setFieldValue(`trip.${index}.tripPickAddress`, selectedOption ? selectedOption.id : "");
                                                                }}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickAddress && touched.trip && touched.trip[index] && touched.trip[index].tripPickAddress
                                                                    ? errors.trip[index].tripPickAddress
                                                                    : null}
                                                            />
                                                        </div>
                                                        <div className={classes.flexWrapper}>
                                                            <Button
                                                                fullWidth
                                                                startIcon={<EditIcon />}
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => { toggleModal("modal2"); handleButtonClick(item.tripPickShipId) }}
                                                                disabled={item.tripPickShipId?.length === 0}
                                                            >
                                                                Edit Address
                                                            </Button>
                                                            {/* <Button
                                                                fullWidth
                                                                startIcon={<AddIcon />}
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => toggleModal("modal1")}
                                                            >
                                                                Add New Address
                                                            </Button> */}
                                                        </div>

                                                        <div className={classes.flexWrapper}>
                                                            <CustomDatePickerNew
                                                                selectedDate={item.tripPickDate}

                                                                minDate={new Date()}
                                                                name={`trip.${index}.tripPickDate`}
                                                                placeholder="Pickup Date"
                                                                error={touched.trip && touched.trip[index] && touched.trip[index].tripPickDate && errors.trip && errors.trip[index] && errors.trip[index].tripPickDate
                                                                    ? errors.trip[index].tripPickDate
                                                                    : null}
                                                                handleDateChange={(date: string) => {
                                                                    setFieldValue(`trip.${index}.tripPickDate`, date);
                                                                }}
                                                            />

                                                            <CustomTimePicker
                                                                label="Time"
                                                                value={item.tripPickTime}
                                                                onChange={handleChange}
                                                                readOnly={false}
                                                                name={`trip.${index}.tripPickTime`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickTime && touched.trip && touched.trip[index] && touched.trip[index].tripPickTime
                                                                    ? errors.trip[index].tripPickTime
                                                                    : null}
                                                            />
                                                        </div>
                                                        <div className={classes.flexWrapperCenter}>
                                                            <InputBox
                                                                label="Add Job Requirement"
                                                                value={item.tripPickJobRequirement}
                                                                onChange={handleChange}
                                                                name={`trip.${index}.tripPickJobRequirement`}
                                                                type="text"
                                                                disabled={false}
                                                                multiline
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripPickJobRequirement && touched.trip && touched.trip[index] && touched.trip[index].tripPickJobRequirement
                                                                    ? errors.trip[index].tripPickJobRequirement
                                                                    : null}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    {/* deliver to */}
                                                    <Grid item xs={6} key={index}>
                                                        <div className={classes.line}></div>
                                                        <Typography
                                                            variant="body1"
                                                            gutterBottom
                                                        >
                                                            TRIP {index + 1}-DELIVER TO
                                                        </Typography>
                                                        <div className={classes.flexWrapper}>
                                                            <SelectorComp
                                                                label="Vehicle Type"
                                                                value={item.tripDeliverVehicleType}
                                                                options={formattedVehiclesTypes}
                                                                name={`trip.${index}.tripDeliverVehicleType`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripDeliverVehicleType && touched.trip && touched.trip[index] && touched.trip[index].tripDeliverVehicleType
                                                                    ? errors.trip[index].tripDeliverVehicleType
                                                                    : null}
                                                                disabled
                                                            />
                                                            <SelectorComp
                                                                label="Groups"
                                                                value={item.tripDeliverGroupType}
                                                                onChange={handleChange}
                                                                options={ADDRESS_GROUPS}
                                                                name={`trip.${index}.tripDeliverGroupType`}

                                                            />
                                                        </div>
                                                        <div className={classes.flexWrapper}>
                                                            <SelectorComp
                                                                label="Country"
                                                                value={item.tripDeliverCounty}
                                                                onChange={handleChange}
                                                                options={COUNTRIES}
                                                                name={`trip.${index}.tripDeliverCounty`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripDeliverCounty && touched.trip && touched.trip[index] && touched.trip[index].tripDeliverCounty
                                                                    ? errors.trip[index].tripDeliverCounty
                                                                    : null}
                                                            />
                                                            <SelectorComp
                                                                label="State"
                                                                onChange={handleChange}
                                                                value={item.tripDeliverState}
                                                                options={item.tripDeliverCounty === "australia" ? AU_STATES : item.tripDeliverCounty === "new_zealand" ? NZ_STATES : []}
                                                                name={`trip.${index}.tripDeliverState`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripDeliverState && touched.trip && touched.trip[index] && touched.trip[index].tripDeliverState
                                                                    ? errors.trip[index].tripDeliverState
                                                                    : null}
                                                            />
                                                        </div>
                                                        <div className={classes.flexWrapper}>
                                                            <MultiSelectDropDownComp<any>
                                                                options={values.company && item.tripDeliverState ? addresses.filter((address) => address.state === item.tripDeliverState) : []}
                                                                label="Ship ID"
                                                                name={`trip.${index}.tripDeliverShipId`}
                                                                value={item.tripDeliverShipId}
                                                                getOptionLabel={(option) => option.id.toString()}
                                                                getOptionId={(option) => option.id}
                                                                onChange={(event, selectedOption) => {
                                                                    const newSelectedOptions = [...selectedDeliverIds];
                                                                    newSelectedOptions[index] = {
                                                                        ...selectedOption,
                                                                        index: index
                                                                    };
                                                                    setSelectedDeliverIds(newSelectedOptions);
                                                                    setFieldValue(`trip.${index}.tripDeliverShipId`, selectedOption ? selectedOption.id : "");
                                                                    setFieldValue(`trip.${index}.tripDeliverAddress`, selectedOption ? selectedOption.id : "");
                                                                }}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripDeliverShipId && touched.trip && touched.trip[index] && touched.trip[index].tripDeliverShipId
                                                                    ? errors.trip[index].tripDeliverShipId
                                                                    : null}
                                                            />
                                                            <MultiSelectDropDownComp
                                                                options={values.company && item.tripDeliverState ? addresses.filter((address) => address.state === item.tripDeliverState) : []}
                                                                label="Address"
                                                                name={`trip.${index}.tripDeliverAddress`}
                                                                value={item.tripDeliverShipId}
                                                                getOptionLabel={(option) => `${option.company_name} - ${option.street_number}, ${option.city}, ${option.state}, ${option.postcode}`}
                                                                getOptionId={(option) => option.id}
                                                                onChange={(event, selectedOption) => {
                                                                    const newSelectedOptions = [...selectedDeliverIds];
                                                                    newSelectedOptions[index] = {
                                                                        ...selectedOption,
                                                                        index: index
                                                                    };
                                                                    setSelectedDeliverIds(newSelectedOptions);
                                                                    setFieldValue(`trip.${index}.tripDeliverShipId`, selectedOption ? selectedOption.id : "");
                                                                    setFieldValue(`trip.${index}.tripDeliverAddress`, selectedOption ? selectedOption.id : "");
                                                                }}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripDeliverAddress && touched.trip && touched.trip[index] && touched.trip[index].tripDeliverAddress
                                                                    ? errors.trip[index].tripDeliverAddress
                                                                    : null}
                                                            />
                                                        </div>
                                                        <div className={classes.flexWrapper}>
                                                            <Button
                                                                fullWidth
                                                                startIcon={<EditIcon />}
                                                                color="primary"
                                                                variant="contained"
                                                                disabled={item.tripDeliverShipId?.length === 0}
                                                                onClick={() => { toggleModal("modal2"); handleButtonClick(item.tripDeliverShipId) }}
                                                            >
                                                                Edit Address
                                                            </Button>
                                                            {/* <Button
                                                                fullWidth
                                                                startIcon={<AddIcon />}
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => toggleModal("modal1")}
                                                            >
                                                                Add New Address
                                                            </Button> */}
                                                        </div>

                                                        <div className={classes.flexWrapper}>


                                                            <CustomDatePickerNew
                                                                selectedDate={item.tripDeliverDate}
                                                                handleDateChange={(date: string) => {
                                                                    setFieldValue(`trip.${index}.tripDeliverDate`, date);
                                                                }}
                                                                placeholder="Delivery Date"
                                                                name={`trip.${index}.tripDeliverDate`}
                                                                error={touched.trip && touched.trip[index] && touched.trip[index].tripDeliverDate && errors.trip && errors.trip[index] && errors.trip[index].tripDeliverDate
                                                                    ? errors.trip[index].tripDeliverDate
                                                                    : null}
                                                                minDate={item.tripPickDate}
                                                            />

                                                            <CustomTimePicker
                                                                label="Time"
                                                                value={item.tripDeliverTime}
                                                                onChange={handleChange}
                                                                readOnly={false}
                                                                name={`trip.${index}.tripDeliverTime`}
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripDeliverTime && touched.trip && touched.trip[index] && touched.trip[index].tripDeliverTime
                                                                    ? errors.trip[index].tripDeliverTime
                                                                    : null}
                                                            />
                                                        </div>
                                                        <div className={classes.flexWrapperCenter}>
                                                            <InputBox
                                                                label="Add Job Requirement"
                                                                value={item.tripDeliverJobRequirement}
                                                                onChange={handleChange}
                                                                name={`trip.${index}.tripDeliverJobRequirement`}
                                                                type="text"
                                                                disabled={false}
                                                                multiline
                                                                error={errors.trip && errors.trip[index] && errors.trip[index].tripDeliverJobRequirement && touched.trip && touched.trip[index] && touched.trip[index].tripDeliverJobRequirement
                                                                    ? errors.trip[index].tripDeliverJobRequirement
                                                                    : null}
                                                            />
                                                            {index !== 0 && <DeleteIcon fontSize='large' color="primary" style={{ cursor: "pointer" }} onClick={() => remove(index)} />}
                                                        </div>
                                                    </Grid>
                                                </>

                                            })}
                                            <Grid item xs={12} >
                                                <div
                                                    className={classes.flexWrapperIcon}
                                                    onClick={() => {
                                                        const lastTrip = values.trip[values.trip.length - 1];
                                                        push({
                                                            tripPickVehicleType: lastTrip.tripPickVehicleType,
                                                            tripPickGroupType: '',
                                                            tripPickCountry: countryInitial,
                                                            tripPickState: lastTrip.tripDeliverState,
                                                            tripPickShipId: lastTrip.tripDeliverShipId,
                                                            tripPickAddress: lastTrip.tripDeliverShipId,
                                                            tripPickDate: '',
                                                            tripPickTime: '',
                                                            tripPickJobRequirement: '',
                                                            tripDeliverVehicleType: lastTrip.tripPickVehicleType,
                                                            tripDeliverGroupType: '',
                                                            tripDeliverCounty: countryInitial,
                                                            tripDeliverState: '',
                                                            tripDeliverShipId: '',
                                                            tripDeliverAddress: '',
                                                            tripDeliverDate: '',
                                                            tripDeliverTime: '',
                                                            tripDeliverJobRequirement: ''
                                                        })
                                                    }}>
                                                    <AddIcon />
                                                    <div>Add Trip</div>
                                                </div>
                                            </Grid>
                                        </>
                                    )}

                                </FieldArray >

                            </Grid>
                            {/* trip section  end*/}


                            {/* job section */}
                            {selectedMovementType === "containers" || job?.movement_type?.code === "containers" ?
                                <>
                                    <div className={classes.line}></div>
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                    >
                                        JOB DETAILS
                                    </Typography>
                                    <Grid container spacing={4}>
                                        <Grid item xs={4} >
                                            <InputBox
                                                value={values.jobDetailVesselName}
                                                onChange={handleChange}
                                                name={`jobDetailVesselName`}
                                                type="text"
                                                label="Vessel Name"
                                                disabled={false}
                                                error={errors.jobDetailVesselName && touched.jobDetailVesselName ? errors.jobDetailVesselName : null}

                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CustomDatePickerNew
                                                selectedDate={values.jobDetailETA}
                                                handleDateChange={(date: string) => {
                                                    setFieldValue(`jobDetailETA`, date);
                                                }}
                                                placeholder="Vessel ETA"
                                                name={`jobDetailETA`}
                                                error={errors.jobDetailETA && touched.jobDetailETA ? errors.jobDetailETA : null}

                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputBox
                                                value={values.jobDetailVoyage}
                                                onChange={handleChange}
                                                name={`jobDetailVoyage`}
                                                type="text"
                                                label="Voyage"
                                                disabled={false}
                                                error={errors.jobDetailVoyage && touched.jobDetailVoyage ? errors.jobDetailVoyage : null}

                                            />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <div
                                                className={classes.flexWrapperIcon}
                                                onClick={() => setShowContainerTable((prev) => !prev)}
                                            >
                                                <AddIcon />
                                                <div>Add Container Details</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {isContainer &&
                                        <CreateJobTable
                                            initialValuesOld={initialValuesOld}
                                            onClickAddNew={() => { toggleModal("modal3"); setEditingContainer(null); setFileContainer(null); setDocTypeContainer("") }}
                                            onEditContainer={handleEditContainer}
                                            onDeleteContainer={handleDeleteContainer}
                                        />}

                                </> :
                                <>
                                    {/* Job detail all start */}
                                    <div className={classes.line}></div>
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                    >
                                        JOB DETAILS
                                    </Typography>
                                    <FieldArray name="jobDetails">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {values.jobDetails.length > 0 &&
                                                    values.jobDetails.map((jobDetail, index) => (
                                                        <Grid container spacing={2} key={index}>
                                                            <Grid item xs={3}>
                                                                <SelectorComp
                                                                    label="Type of Package"
                                                                    value={jobDetail.jobDetailPackage}
                                                                    options={packageTypeOptions}
                                                                    name={`jobDetails.${index}.jobDetailPackage`}
                                                                    onChange={(event) => {
                                                                        const newValue = event.target.value;
                                                                        setFieldValue(`jobDetails.${index}.jobDetailPackage`, newValue);
                                                                        toggleModal(newValue === "Other" ? "modal5" : null)
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <InputBox
                                                                    value={jobDetail.jobDetailQty}
                                                                    onChange={handleChange}
                                                                    name={`jobDetails.${index}.jobDetailQty`}
                                                                    type="number"
                                                                    label="Qty"
                                                                    disabled={false}
                                                                    error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailQty && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailQty
                                                                        ? errors.jobDetails[index].jobDetailQty
                                                                        : null}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <InputBox
                                                                    label="SKU"
                                                                    value={jobDetail.jobDetailSKU}
                                                                    onChange={handleChange}
                                                                    name={`jobDetails.${index}.jobDetailSKU`}
                                                                    type="text"
                                                                    disabled={false}
                                                                    error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailSKU && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailSKU
                                                                        ? errors.jobDetails[index].jobDetailSKU
                                                                        : null}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <InputBox
                                                                    label="Description"
                                                                    value={jobDetail.jobDetailDesc}
                                                                    onChange={handleChange}
                                                                    name={`jobDetails.${index}.jobDetailDesc`}
                                                                    type="text"
                                                                    disabled={false}
                                                                    error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailDesc && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailDesc
                                                                        ? errors.jobDetails[index].jobDetailDesc
                                                                        : null}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <InputBox
                                                                    label="Lot"
                                                                    value={jobDetail.jobDetailLot}
                                                                    onChange={handleChange}
                                                                    name={`jobDetails.${index}.jobDetailLot`}
                                                                    type="text"
                                                                    disabled={false}
                                                                    error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailLot && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailLot
                                                                        ? errors.jobDetails[index].jobDetailLot
                                                                        : null}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <InputBox
                                                                    value={jobDetail.jobDetailHeight}
                                                                    onChange={handleChange}
                                                                    name={`jobDetails.${index}.jobDetailHeight`}
                                                                    type="number"
                                                                    label="H (ft)"
                                                                    disabled={false}
                                                                    error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailHeight && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailHeight
                                                                        ? errors.jobDetails[index].jobDetailHeight
                                                                        : null}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <InputBox
                                                                    value={jobDetail.jobDetailWidth}
                                                                    onChange={handleChange}
                                                                    name={`jobDetails.${index}.jobDetailWidth`}
                                                                    type="number"
                                                                    label="W (ft)"
                                                                    disabled={false}
                                                                    error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailWidth && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailWidth
                                                                        ? errors.jobDetails[index].jobDetailWidth
                                                                        : null}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <InputBox
                                                                    value={jobDetail.jobDetailLength}
                                                                    onChange={handleChange}
                                                                    name={`jobDetails.${index}.jobDetailLength`}
                                                                    type="number"
                                                                    label="L (ft)"
                                                                    disabled={false}
                                                                    error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailLength && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailLength
                                                                        ? errors.jobDetails[index].jobDetailLength
                                                                        : null}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <div className={classes.flexWrapperCenter}>
                                                                    <InputBox
                                                                        label="Net Weight (kg)"
                                                                        value={jobDetail.jobDetailNetWeight}
                                                                        onChange={handleChange}
                                                                        name={`jobDetails.${index}.jobDetailNetWeight`}
                                                                        type="number"
                                                                        disabled={false}
                                                                        error={errors.jobDetails && errors.jobDetails[index] && errors.jobDetails[index].jobDetailNetWeight && touched.jobDetails && touched.jobDetails[index] && touched.jobDetails[index].jobDetailNetWeight
                                                                            ? errors.jobDetails[index].jobDetailNetWeight
                                                                            : null}
                                                                    />
                                                                    {/* {index === 0 && <DeleteIcon fontSize='large' style={{ cursor: "none", color: "gray" }} />} */}
                                                                    {index !== 0 && <DeleteIcon fontSize='large' color="primary" style={{ cursor: "pointer" }} onClick={() => remove(index)} />}

                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                <Grid item xs={12} >
                                                    <div
                                                        className={classes.flexWrapperIcon}
                                                        onClick={() => push({
                                                            jobDetailPackage: '',
                                                            jobDetailQty: '',
                                                            jobDetailSKU: '',
                                                            jobDetailDesc: '',
                                                            jobDetailLot: '',
                                                            jobDetailHeight: '',
                                                            jobDetailWidth: '',
                                                            jobDetailLength: '',
                                                            jobDetailNetWeight: '',
                                                        })}>
                                                        <AddIcon /><div>Add Line</div>
                                                    </div>
                                                </Grid>
                                            </div>
                                        )}
                                    </FieldArray>

                                    <Grid container spacing={2} style={{ margin: "20px 0" }}>

                                        <Grid item xs={3} style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                                            <SelectorComp
                                                label="Temperature"
                                                name="temperature"
                                                value={values.temperature}
                                                onChange={handleChange}
                                                options={TEMPERATURE_TYPE}
                                                error={errors.temperature && touched.temperature ? errors.temperature : null}

                                            />
                                            {toggleTemp || values.temperatureDetail ? <InputBox
                                                label="Temperature Instructions"
                                                value={values.temperatureDetail}
                                                onChange={handleChange}
                                                name="temperatureDetail"
                                                type="text"
                                                disabled={false}
                                                multiline={true}
                                            /> : <Button
                                                fullWidth
                                                className={classes.btnCreate}
                                                color="primary"
                                                variant="contained"
                                                style={{ height: "50px" }}
                                                onClick={() => setToggleTemp((prev) => !prev)}
                                            >
                                                Add Temperature Details
                                            </Button>}
                                            <InputBox
                                                label="Total Qty"
                                                value={values.totalQty}
                                                onChange={handleChange}
                                                name="totalQty"
                                                type="number"
                                                disabled={false}
                                                error={errors.totalQty && touched.totalQty ? errors.totalQty : null}
                                            />


                                            <InputBox
                                                label="Total Net Weight (kg)"
                                                value={values.netWeight}
                                                onChange={handleChange}
                                                name="netWeight"
                                                type="number"
                                                disabled={false}
                                                error={errors.netWeight && touched.netWeight ? errors.netWeight : null}
                                            />
                                            <InputBox
                                                label="Total Gross Weight (kg)"
                                                value={values.grossWeight}
                                                onChange={handleChange}
                                                name="grossWeight"
                                                type="number"
                                                disabled={false}
                                                error={errors.grossWeight && touched.grossWeight ? errors.grossWeight : null}
                                            />

                                            <InputBox
                                                label="Volume"
                                                value={values.volume}
                                                onChange={handleChange}
                                                name="volume"
                                                type="text"
                                                disabled={false}
                                                error={errors.volume && touched.volume ? errors.volume : null}
                                            />
                                        </Grid>

                                        <Grid item xs={3} style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                                            <InputBox
                                                label="Goods Description"
                                                value={values.goodsDesc}
                                                onChange={handleChange}
                                                name="goodsDesc"
                                                type="text"
                                                disabled={false}
                                                error={errors.goodsDesc && touched.goodsDesc ? errors.goodsDesc : null}

                                            />
                                            <SelectorComp
                                                label="Pallet Account Type"
                                                onChange={handleChange}
                                                options={PALLET_ACCOUNT_TYPE}
                                                value={values.palletAcc}
                                                name={'palletAcc'}
                                            />

                                            <SelectorComp
                                                label="Pallet Account Action"
                                                onChange={handleChange}
                                                options={PALLET_ACCOUNT_ACTION}
                                                value={values.palletAccNumber}
                                                name={'palletAccNumber'}
                                            />

                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                    </Grid>
                                    {/* Job detail all end */}
                                </>
                            }

                            {selectedMovementType !== "containers" && <>
                                <div className={classes.line}></div>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                >
                                    UPLOAD DOCUMENTS
                                </Typography>
                                <div className={classes.flexColumn}>

                                    <div {...getRootProps({ className: 'dropzone' })} className={classes.draganddrop}>
                                        <input {...getInputProps()} />
                                        {file ? (
                                            <p>Selected file: {file.name}</p>
                                        ) : (
                                            <p>Drag 'n' drop to upload, or browse documents</p>
                                        )}
                                    </div>
                                    <SelectorComp
                                        label="Document Type"
                                        value={docType}
                                        onChange={handleDocTypeChange}
                                        options={DOCS}
                                        name="document"
                                    />
                                    <div className={classes.flexWrapperIcon} onClick={handleAddDocument}><AddIcon /><div>Add Documents</div></div>
                                    <div className={classes.previewTitle}>Attachments</div>
                                    <div className={classes.previewContainer}>
                                        {Object.entries(uploadedFiles).map(([type, uploadedFile]) => (
                                            uploadedFile && (
                                                <div key={type} className={classes.previewCard}>
                                                    <div className={classes.cardContent}>
                                                        <div className={classes.cardItemFileName}>
                                                            <strong>File:</strong> {uploadedFile.name}
                                                        </div>
                                                        <div className={classes.cardItemType}>
                                                            <strong>Type:</strong> {type}
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => handleDeleteFile(type as DocType)}
                                                        className={classes.deleteButton}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </>}

                            <div className={classes.line}></div>
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                JOB CHARGES
                            </Typography>

                            <Grid container spacing={4}>

                                <Grid item xs={3}>

                                    <SelectorComp
                                        label="Service Type"
                                        value={values.serviceType}
                                        name="serviceType"
                                        onChange={handleChange}
                                        options={SERVICE_TYPE}
                                        error={errors.serviceType && touched.serviceType ? errors.serviceType : null}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SelectorComp
                                        label="Post To"
                                        value={values.postTo}
                                        onChange={handleChange}
                                        options={POST_TO}
                                        name="postTo"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputBox
                                        label="Buy Price"
                                        value={values.buyPrice}
                                        onChange={handleChange}
                                        name="buyPrice"
                                        type="number"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputBox
                                        label="Sell Price"
                                        onChange={handleChange}
                                        name="sellPrice"
                                        type="text"
                                        disabled={false}
                                        value={values.sellPrice}

                                    />
                                </Grid>
                            </Grid>


                            <div className={classes.line}></div>
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                JOB INSTRUCTIONS
                            </Typography>

                            <Grid container spacing={0}>

                                <Grid item xs={12}>
                                    <InputBox
                                        label=""
                                        value={values.jobInstruction}
                                        onChange={handleChange}
                                        name="jobInstruction"
                                        type="text"
                                        disabled={false}
                                        multiline={true}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Button
                            type='submit'
                            fullWidth
                            className={classes.btnCreate}
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            Submit
                        </Button>
                    </Form>
                )}

            </Formik>
        </>
    )
}

export default EditJobV2