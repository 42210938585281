import ModalWrapper from '../wrappers/ModalWrapper';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import InputBox from '../ui/InputBox';
import { validationJobTypeModal } from '../lib/validationSchema';

interface JobTypeModalProps {
    open: boolean;
    close: () => void;
    setAdditionalJobType: (value: string) => void; 
    additionalJobType: string; 
}

const JobTypeModal = ({
    open,
    close,
    setAdditionalJobType,
    additionalJobType,
}: JobTypeModalProps) => {
    const handleSubmit = (values: { jobtype: string }, { setSubmitting, resetForm }: any) => {
        setAdditionalJobType(values.jobtype);
        setSubmitting(false); 
        resetForm();
        close(); 
    };

    const handleClose = (resetForm: () => void) => {
        resetForm(); 
        close();
    };
    return (
        <Formik
            initialValues={{ jobtype: additionalJobType }} 
            onSubmit={handleSubmit}
            enableReinitialize={true} 
            validationSchema={validationJobTypeModal} 
        >
            {({ isSubmitting, handleChange, values, errors, touched, resetForm }) => (
                <ModalWrapper open={open} onClose={() => handleClose(resetForm)} heading={"Additional Job Type"}>
                    <Form>
                        <InputBox
                            value={values.jobtype} 
                            onChange={handleChange} 
                            name="jobtype" 
                            type="text"
                            label="Job type details"
                            error={errors.jobtype && touched.jobtype ? errors.jobtype : null}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            color="secondary"
                            variant="contained"
                            disabled={isSubmitting} 
                            style={{ margin: "20px 0" }}
                        >
                            Submit
                        </Button>
                    </Form>
                </ModalWrapper>
            )}
        </Formik>
    );
};

export default JobTypeModal;
