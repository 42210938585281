import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import Login from './Login/Login';
import CompanyRegister from './CompanyRegister/CompanyRegister';
import { Switch, Route } from 'react-router-dom'
import { routes } from '../../routes';
import DriverRegister from './DriverRegister/DriverRegister';
import CustomerRegister from './CustomerRegister/CustomerRegister';

import AccountVerification from './AccountVerification/AccountVerification';
import RateUser from './RateUser/RateUser';
import ForgotPasswordEmail from './ForgotPasswordEmail/ForgotPasswordEmail';
import ForgotPasswordChange from './ForgotPasswordChange/ForgotPasswordChange';
import ScheduledDeliveryReports from '../Home/Report/ScheduledDeliveryReport';
import CarrierRegister from './CarrierRegister/CarrierRegister';

const useStyles = makeStyles(() => ({
    box: {
        background: '#223a59'
    }
}))

function Landing() {
    const classes = useStyles()
    console.log(routes.landing.registerCarrier, 'HHHHH', routes.landing.registerCompany)
    return (
        <Box
            className={classes.box}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            
        >
            <Switch>
                <Route exact path={routes.landing.accountVerification}><AccountVerification /></Route>
                <Route exact path={routes.landing.rateUser}><RateUser /></Route>
                <Route exact path={routes.landing.forgotPassword}><ForgotPasswordEmail /></Route>
                <Route exact path={routes.landing.forgotPasswordChange}><ForgotPasswordChange /></Route>
                <Route exact path={routes.landing.login}><Login /></Route>  
                
                <Route exact path={routes.landing.registerCompany}><CompanyRegister /></Route>


                <Route exact path={routes.landing.registerCarrier}><CarrierRegister /></Route>

                <Route exact path={routes.landing.registerDriver}><DriverRegister /></Route>
                <Route exact path={routes.landing.registerCustomer}><CustomerRegister /></Route>
                <Route exact path={ routes.home.report.scheduled_delivery_report}><ScheduledDeliveryReports /></Route>
                <Route exact path={routes.landing.registerDriverMobile}><DriverRegister hideBack/></Route>
            </Switch>
        </Box>
    )
}


export default Landing