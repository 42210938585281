import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles, Grid, FormGroup, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import CustomSearchInput from '../../ui/CustomSearchInput';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import useDebounce from '../../hooks/useDebounce';
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

const useStyles = makeStyles(() => ({
    root: {
        height: 700,
        width: '100%',
        overflowX: 'auto',
    },
    dataGrid: {
        minWidth: "fit-content",
        '& .MuiDataGrid-menuIconButton': {
            color: 'white',
        },
    },
    search: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px 0',
    },
    errorMessage: {
        color: 'red',
        margin: '20px 0',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    },
}));

const storage = new WebStorage();
const baseUrl = process.env.REACT_APP_BASE_URL;

const CarrierPages = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [data, setData] = useState({
        rows: [],
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 20],
        page: 1,
        pageSize: 10,
    });

    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchCarriers();
    }, [debouncedSearch, data.page, data.pageSize]);

    const fetchCarriers = async () => {
        setLoading(true);
        try {
            const headers = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });

           const filters = { reference: debouncedSearch };
           const encodedFilters = encodeURIComponent(JSON.stringify(filters)); 

            const response = await fetch(
                // `${baseUrl}/api/v3/getAllCarriersForAdmin`,
                 `https://movit-api.devtrust.biz/api/v3/getAllCarriersForAdmin?search=${encodedFilters}`,
                { method: 'GET', headers }
            );
            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();
            const formattedRows = result.carriers.map((carrier: any) => ({
                id: carrier.id,
                companyname: carrier.companyname,
                primarycontactperson: carrier.primarycontactperson,
                operationscontactname: carrier.operationscontactname,
                accountcontactemail: carrier.accountcontactemail,
                accountcontactname: carrier.accountcontactname,
                accountcontactphone: carrier.accountcontactphone,
                status: carrier?.userDetails?.status,
                user_id: carrier.user_id
            }));

            setData((prevData) => ({
                ...prevData,
                rows: formattedRows,
                totalRows: result.totalCarriers || result.carriers.length,
            }));
        } catch (err) {
            console.error('Fetch error:', err);
        } finally {
            setLoading(false);
        }
    };

const handleStatusChange = async (userId: number, currentStatus: string) => {
    const newStatus = currentStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    fetchCarriers();
    try {
        const response = await fetch(`https://movit-api.devtrust.biz/api/v1/carrier/status/update/status/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            },
            body: JSON.stringify({ status: newStatus }),
        });
        if (!response.ok) {
            throw new Error('Failed to update status');
        }
        if (response) {
            fetchCarriers();
        }
        const data = await response.json();
        toast.success(`Carrier ${newStatus.toLocaleLowerCase()} successfully`);
        setData((prevData) => ({
            ...prevData,
            rows: prevData.rows.map((row) =>
                row.user_id === userId
                    ? { ...row, userDetails: { ...row.userDetails, status: newStatus } }
                    : row
            ),
        }));
    } catch (error) {
        toast.error('Error updating status');
    }
};
  const handleDelete = async (userId: string) => {
    try {
        const response = await fetch(`https://movit-api.devtrust.biz/api/v1/delete/carrier/carrier/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            },
            body: JSON.stringify({
                user_id: userId, 
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to delete carrier');
        }
        if (response) {
            fetchCarriers();
        }
        setData((prevData) => ({
            ...prevData,
            rows: prevData.rows.filter((row) => row.id !== userId),
        }));
    } catch (error) {
        console.error('Error deleting carrier:', error);
    }
};

const handleSearch = (query: string) => {
    setSearch(query);
};

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'companyname', headerName: 'Company Name', minWidth: 200, align: 'center', headerAlign: 'center' },
            { field: 'primarycontactperson', headerName: 'Primary Contact', minWidth: 200, align: 'center', headerAlign: 'center' },
            { field: 'operationscontactname', headerName: 'Operations  Name', minWidth: 200, align: 'center', headerAlign: 'center' },
            { field: 'accountcontactemail', headerName: 'Account Email', minWidth: 200, align: 'center', headerAlign: 'center' },
            { field: 'accountcontactname', headerName: 'Account Name',minWidth: 200, align: 'center', headerAlign: 'center'},
            { field: 'accountcontactphone', headerName: 'Account Phone',minWidth: 200, align: 'center', headerAlign: 'center' },
            {
                field: 'status',
                headerName: 'Status',
                minWidth: 200, align: 'center', headerAlign: 'center',
                renderCell: (params) => {
                    const status = params.row?.status;  
                    const currentStatus = status || 'INACTIVE';  
                    const userId = params.row?.user_id; 
            
                    return (
                        <Grid container alignItems="center" justifyContent="center">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={currentStatus === 'ACTIVE'}
                                            onChange={() => {
                                                if (userId) {
                                                    handleStatusChange(userId, currentStatus);
                                                } else {
                                                    console.warn("Missing user ID for row:", params.row);
                                                }
                                            }}
                                            color="primary"
                                            disabled={!userId} 
                                        />
                                    }
                                    label={currentStatus}
                                />
                            </FormGroup>
                        </Grid>
                    );
                },
            },
        {
            field: 'delete',
            headerName: 'Action',
            minWidth: 150, align: 'center', headerAlign: 'center',
            renderCell: (params) => (
                <IconButton
                    color="secondary"
                    onClick={() => {
                        Swal.fire({
                            title: 'Are you sure?',
                            text: "Want to delete this carrier record? If you delete this record, it will not be recoverable in the future.",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, delete it!',
                            confirmButtonColor:'red',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                handleDelete(params.row.user_id);
                                Swal.fire('Deleted!', 'The record has been deleted.', 'success');
                            }
                        });
                    }}
                >
                    <MdDelete />
                </IconButton>
            ),
        }
        ],
        []
    );
   
  

    return (
        <>
            <SectionHeader subtitle="Carrier Management" title="All Carriers" />
            <div className={classes.search}>
                <CustomSearchInput
                    label="Search by Carrier Name"
                    onSearch={handleSearch}
                    variant="outlined"
                    value={search}
                />
            </div>
            {data.rows.length === 0 && !loading && (
                <div className={classes.errorMessage}>No carrier found.</div>
            )}
            <div className={classes.root}>
                <DataGrid
                    className={classes.dataGrid}
                    columns={columns}
                    disableSelectionOnClick
                    loading={loading}
                    rowCount={data.totalRows}
                    rowsPerPageOptions={data.rowsPerPageOptions}
                    pagination
                    paginationMode="server"
                    page={data.page - 1}
                    pageSize={data.pageSize}
                    rows={data.rows}
                    onPageChange={(newPage) =>
                        setData((prev) => ({ ...prev, page: newPage + 1 }))
                    }
                    onPageSizeChange={(newPageSize) =>
                        setData((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }))
                    }
                />
            </div>
        </>
    );
};

export default CarrierPages;
