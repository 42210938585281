import {
  ALL,
  ASSIGNED,
  AVAILABLE,
  BIDS,
  BIDS_TO_CONFIRM,
  COMPLETED_JOBS,
  DELIVERED_JOBS,
  IN_PROGRESS,
  MY_BIDS,
  PENDING,
  PICKED_UP,
  POSTED,
  JOB_REPORT,
  MY_IN_PROGRESS,
  CUSTOMER_IN_PROGRESS,
  MYJOB
} from './Components/Home/Jobs/Jobs.constans';

import { FAILED_QUOTES, ACTIVE_QUOTES, PENDING_QUOTES, SUCCESSFUL_QUOTES } from './Components/Home/Quotes/Quotes.constants';

import { COMPLETED_JOBS_MILEAGE } from './Components/Home/Sidebar/Sidebar.types';

export const routes = {
  landing: {
    accountVerification: '/account/verification',
    login: ['/login', '/'],
    registerCompany: '/register/company',
    registerDriver: '/register/driver',
    registerCarrier: '/register/carrier',
    registerCustomer: '/register/customer',
    registerDriverMobile: '/register/mobile/driver',
    rateUser: '/rate/user',
    forgotPassword: '/forgot-password',
    forgotPasswordChange: '/forgot-password/change',
  },
  home: {
    root: '/home',
    change_password: '/change-password',
    dashboard_menu: {
      root: '/dashboard',
      dashboard: '/main',
      dashboard_weekly: '/weekly',

    },
    dashboard_v2: {
      root: '/dashboardv2',
      main: '/main',
    },
    overview: '/overview',
    map_view: '/map-view',
    transport_management: {
      root: '/transport',
    },
    operations: {
      root: '/operations',
    },
    sea_freight_management: {
      root: '/sea-freight',
    },
    jobs: {
      create_new_job: '/create/job',
      jobs: '/jobs',
      create_new_assigned_job: '/create/allocated-job',
      job_report: '/' + JOB_REPORT,
      job_report_mileage: '/' + 1,
      show_jobs: {
        root: '/show/jobs',
        pending: '/' + PENDING,
        posted: '/' + POSTED,
        myjob: '/' + MYJOB,
        bids_to_confirm: '/' + BIDS_TO_CONFIRM,
        my_bids: '/' + MY_BIDS,
        all: '/' + ALL,
        available: '/' + AVAILABLE,
        our_assigned: '/' + ASSIGNED,
        in_progress: '/' + IN_PROGRESS,
        my_in_progress: '/' + MY_IN_PROGRESS,
        bids: '/' + BIDS,
        picked_up: '/' + PICKED_UP,
        delivered: '/' + DELIVERED_JOBS,
        completed: '/' + COMPLETED_JOBS,
        jobs_in_progress: '/' + CUSTOMER_IN_PROGRESS,
      },
    },
    report: {
      root: '/report',
      stats: '/statistics',
      marketplace_mgmnt_stat_report: '/marketplace_mgmnt_stat_report',
      overdue_collection: '/collections',
      overdue_deliveries: '/deliveries',
      no_pod_documents: '/no_pod_docs',
      profitability_report: '/profitability_report',
      scheduled_delivery_report: '/scheduled_delivery_report',
      scheduled_collection_report: '/scheduled_collection_report',
      cntr_dehire_report: '/cntr_dehire_report',
      cntr_detention_report: '/cntr_detention_report',
      completed_jobs_report: '/completed_jobs_report',
      completed_jobs_mileage_report: '/completed_jobs_mileage_report',
      mpd_reports: '/mpd_reports',
    },
    user_management: {
      root: '/user/management',
      add: '/add',
      all: '/all',
      admin: '/admin/all',
    },
    admin_settings: {
      root: '/admin/settings',
      commission: '/commission',
      margin: '/margin',
      common_address: '/common_address',
      address_management: '/adress_management',
      country_settings: '/country_settings',
      notification_settings: '/notification_settings',
      invoice_reports: '/invoice_reports',
      job_invoiced: '/job/invoiced',
      job_not_invoiced: '/job/uninvoiced',
      rates_management: '/rates_management',

    },
    company_management: {
      root: '/company/management',
      relationship_management: '/relationship_management',
      associate_group_management: '/associate_group_management',
      company_address: '/company_address',
      address_management: '/address_management',
      company_management: '/manage',
      company_fee_settings: '/company_fee_settings',
    },
    customer_management: {
      root: '/customer/management',
      customer_management: '/manage',
      account_management: '/account',
      rates: '/rates',
    },
    warehouse_management: {
      root: '/warehouse/management',
      warehouse_management: '/manage',
      account_management: '/account',
      my_warehouse: '/my',
      stocks: '/stocks',
      scheduled_collection: '/scheduled/collection',
      scheduled_deliveries: '/scheduled/deliveries',
      containers: '/containers',
      on_site: '/onsite',
      stock_ledger: "/stock_ledger"
    },
    driver_management: {
      root: '/driver/management',
      add_driver: '/add',
      my_drivers: '/drivers/list',
      waiting_for_approval: '/waiting_for_approval',
      driver_fee_settings: '/fee/settings',
      driver_management_page: '/page',
    },
    kpi: {
      root: '/kpi',
      dashboard: '/dashboard',
      monthly: '/monthly',
      yearly: '/yearly',
    },
    quotes: {
      root: '/quotes',
      create: '/create',
      upload: '/upload',
      list_quotes: {
        root: '/list',
        pending: '/' + PENDING_QUOTES,
        successful: '/' + SUCCESSFUL_QUOTES,
        failed_quotes: '/' + FAILED_QUOTES,
        active: '/' + ACTIVE_QUOTES,
      },
    },
    carrier_management: {
      root: '/carrier_management',
      all: '/all',
      other: '/other',
    },
  },
};
