import React, { useCallback, useMemo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from './Redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from '@material-ui/core/styles';
import { Redirect, Route, Switch, useLocation, HashRouter } from 'react-router-dom'; // Import HashRouter
import { routes } from './routes';
import { PrivateRoute } from './Components/Common/PrivateRoute/PrivateRoute';
import Landing from './Components/Landing/Landing';
import Home from './Components/Home/Home';
import Loader from './Components/Common/Loader/Loader';
import Chart from 'chart.js';
import { jaJP, enUS } from '@material-ui/core/locale';
import { LANGUAGES } from './Types/Constants';

// Chart.js global settings
// (Chart as any).defaults.global.title.fontFamily = 'Poppins, sans-serif';
// (Chart as any).defaults.global.legend.labels.fontFamily = 'Poppins, sans-serif';
const fontSize = '.8rem'
// Material-UI theme
const theme = {
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#223A59',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        // padding: '15px 14px',
      },
      inputMarginDense: {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: fontSize,
        transform: 'translate(14px, 10px) scale(1)',
      },
      shrink: {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    MuiSelect: {
      icon: {
        color: '#223A59',
      },
      select: {
        fontSize: fontSize,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#ffffff',
        color: '#223A59',
        fontSize: fontSize
      },
      input: {
        color: '#223A59',
        padding: '5px 14px',
      },
    },
    MuiInputAdornment: {
      root: {
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
        },
      },
    },

    MuiCssBaseline: {

      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.6em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.3)',
          outline: '1px solid slategrey',
        },
        '.MuiDateTimePickerToolbar-dateContainer': {
          marginRight: '20px',
        },
        '.MuiDataGrid-window': {
          backgroundColor: '#ffffff',
        },
        '.MuiInput-formControl': {
          backgroundColor: 'transparent'
        }
      },
    },
    MuiDataGrid: {

      root: {

        '& .MuiDataGrid-columnHeader': {

          '&:hover .MuiDataGrid-sortIcon': {
            opacity: 1, // Ensure it stays fully visible on hover as well
          },
          '& .MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
            width: 'auto', // Ensures the icon takes up space
          },
        },

        '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
          visibility: 'visible !important',
          width: 'auto',
        },
        '& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon': {
          opacity: '1 !important',
          transition: 'none',
        },
        '& .MuiDataGrid-sortIcon': {
          opacity: 1,
          visibility: 'visible !',
          color: 'white',
          display: 'block',
          transition: 'none !important',
        },
        backgroundColor: '#ffffff',
        '& .MuiDataGrid-cell': {
          color: '#223A59',
        },
        '& .MuiTypography-body1': {
          fontSize: '12px !important',
        },

        '& .MuiDataGrid-row': {
          backgroundColor: '#ffffff', // Ensure rows have a white background
          fontSize: '12px !important',
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#223A59', // Custom background color for headers
          color: '#223A59', // Custom text color for headers
        },
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor: '#223A59',
          fontSize: '12px !important',
          color: 'white'
        },
        '& .MuiDataGrid-colCellTitle': {
          fontWeight: 'bolder',
          fontSize: '12px !important',

        },
        '.MuiDataGrid-window': {
          backgroundColor: '#223A59 !important', // Ensure the background inside the table is white
        }
      },
    },
  },
};

const { JAPANESE } = LANGUAGES;

export default function App() {
  const loading = useSelector((state: AppState) => state.loader.loading);
  const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);
  const language = useSelector((state: AppState) => state.language);
  const location = useLocation();

  const nonPrivateRoutes = useMemo(() => [
    ...routes.landing.login,
    routes.landing.registerCompany,
    routes.landing.registerDriver,
    routes.landing.registerCustomer,
    routes.landing.registerCarrier,
    routes.landing.registerDriverMobile,
    routes.landing.accountVerification,
    routes.landing.rateUser,
    routes.landing.forgotPassword,
    routes.landing.forgotPasswordChange,
  ].flat(), []);

  const getThemeLocale = useCallback((lang) => {
    switch (lang) {
      case JAPANESE:
        return jaJP;
      default:
        return enUS;
    }
  }, []);
  return (
    <HashRouter> {/* Use HashRouter instead of BrowserRouter */}
      <ThemeProvider theme={createTheme(theme, getThemeLocale(language.selectedLanguage))}>
        <CssBaseline />
        <Loader open={loading.length !== 0} />
        <Suspense fallback={<Loader open={true} />}>
          <Switch>
            <Route exact path={nonPrivateRoutes}>
              {isAuthenticated ? <Redirect to={`/home/dashboard/main`} /> : <Landing />}
            </Route>

            <PrivateRoute path={routes.home.root}>
              <Home />
            </PrivateRoute>

            <Route>Did not find route.</Route>
          </Switch>
        </Suspense>
      </ThemeProvider>
    </HashRouter>
  );
}
