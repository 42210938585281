import { Box, Divider, Grid, Typography } from "@material-ui/core";

interface Props {
    data?: any;
}

const OthersTable = ({ data }: Props) => {

    if (!data) {
        return null;
    }

    const fields = [
        { label: "Company", value: data.company },
        { label: "Movement Type", value: data.movement_type },
        { label: "Shipment No.", value: data.shipment_no },
        { label: "Customer", value: data.customer },
        { label: "Job Type", value: data.job_type },
        { label: "MI4U Ref No.", value: data.reference },
        { label: "Customer Ref.", value: data.customer_reference },
        { label: "Trip Type", value: data.trip_type },
        { label: "Transport Type", value: data.transport_type },
        { label: "Pickup Vehicle Type", value: data.pickup_vehicle_type },
        { label: "Pickup Group Type", value: data.pickup_group },
        { label: "Pickup Country", value: data.pickup_country },
        { label: "Pickup State", value: data.pickup_state },
        { label: "Pickup Ship Id", value: data.pickup_address_id },
        { label: "Pickup Address", value: data.pickup_location },
        { label: "Pickup Date", value: data.pickup_date },
        { label: "Pickup Time", value: data.pickup_time },
        { label: "Pickup Job Requirement", value: data.pickup_job_requirement },
        { label: "Deliver Vehicle Type", value: data.delivery_vehicle_type },
        { label: "Deliver Group Type", value: data.delivery_group },
        { label: "Deliver Country", value: data.delivery_country },
        { label: "Deliver State", value: data.delivery_state },
        { label: "Deliver Ship Id", value: data.delivery_address_id },
        { label: "Deliver Ship Address", value: data.delivery_location },
        { label: "Deliver Date", value: data.delivery_date },
        { label: "Deliver Time", value: data.delivery_time },
        { label: "Deliver Job Requirement", value: data.delivery_job_requirement },
        { label: "Temperature", value: data.temperature },
        { label: "Temperature Instructions", value: data.temperature_instructions },
        { label: "Total Qty", value: data.total_qty },
        { label: "Total Net Weight (kg)", value: data.total_net_weight },
        { label: "Total Gross Weight (kg)", value: data.total_gross_weight },
        { label: "Volume", value: data.volume },
        { label: "Goods Description", value: data.good_description },
        { label: "Pallet Account Type", value: data.pallet_account_type },
        { label: "Pallet Account Action", value: data.pallet_account_number },
        { label: "Service Type", value: data.service_type },
        { label: "Post To", value: data.post_to },
        { label: "Buy Price", value: data.buy_price },
        { label: "Sell Price", value: data.sell_price || "NA" },
        { label: "Job Instructions", value: data.job_instructions },
        { label: "Created At", value: data.created_at },
    ];

    return (
        <Box padding={2}>
            <Grid container spacing={2}>
                {fields.map((field, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {field.label}
                        </Typography>
                        <Typography variant="body1">{field.value}</Typography>
                    </Grid>
                ))}
            </Grid>

           {data.job_details?.length > 0 && <Divider style={{ margin: '20px 0' }} />} 

            {data.job_details && data.job_details?.length > 0 && (
                <Box marginTop={2}>
                    {data.job_details?.map((detail: any, index: number) => (
                        <Box key={index} marginBottom={3}>
                            <Typography variant="subtitle1" color="primary">
                                Job Detail {index + 1}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Package Type
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_package}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Quantity
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_qty}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        SKU
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_sku}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Description
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_desc}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Lot
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_lot}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Height (ft)
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_height}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Width (ft)
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_width}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Length (ft)
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_length}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Net Weight (kg)
                                    </Typography>
                                    <Typography variant="body1">{detail.job_detail_net_weight}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default OthersTable;