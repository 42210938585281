import { Button, Checkbox } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IAddress } from "../../../../../../Application/DTOs/AddressDto/AddressDto.types";





export const getColumns = (t: TFunction, onUpdateStatusCallback: (addressId: string | number, newStatus: boolean) => void, onDelete: (addressId: string | number) => void, isUserAdmin: boolean): GridColumns => {


    return [
        {
            field: 'id',
            headerName: t('Shipping Address ID'),
            width: 200,            
        },
        // {
        //     field: 'country',
        //     headerName: t('country'),
        //     width: 200,
        //     valueGetter: (params) => t((params.row as IAddress).country)
        // },
        {
            field: 'company_name',
            headerName: t('company_name'),
            width: 250
        },
        {
            field: 'street_number',
            headerName: t('street_number'),
            width: 200
        },
        {
            field: 'city',
            headerName: t('suburb_city'),
            width: 200
        },
        {
            field: 'state',
            headerName: t('state'),
            width: 200
        },
        {
            field: 'actions',
            headerName: t('disabled'),
            width: 200,
            sortable: false,
            renderCell: (params) => {
                const address = params.row as IAddress
                if (isUserAdmin) {
                    return <Checkbox color="primary" checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />
                }
                else {
                    if (address.is_common) {
                        return <Checkbox color="primary" disabled readOnly checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />
                    } else {
                        return <Checkbox color="primary" checked={address.is_disabled} onChange={(e) => onUpdateStatusCallback(address.id, e.target.checked)} />


                    }
                }
            }
        }
        ,
        {
            field: 'delete',
            headerName: t('delete'),
            width: 200,
            sortable: false,
            renderCell: (params) => {
                const address = params.row as IAddress
                if (isUserAdmin) {
                    return <Button onClick={() => onDelete(address.id)} variant="contained" color="secondary">{t('delete')}</Button>
                }
                else {
                    if (address.is_common) {
                        return <Button disabled onClick={() => onDelete(address.id)} variant="contained" color="secondary">{t('delete')}</Button>
                    } else {
                        return <Button onClick={() => onDelete(address.id)} variant="contained" color="secondary">{t('delete')}</Button>

                    }
                }
            }
        }
    ]
}