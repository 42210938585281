import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@material-ui/core";

const CustomDatePickerNew = ({
    selectedDate,
    handleDateChange,
    readOnly = false,
    placeholder = "MM/DD/YYYY",
    name,
    error = undefined,
    minDate = undefined,
    maxDate = undefined,
    fullWidth = true,
}) => {
    const parsedDate = selectedDate ? new Date(selectedDate) : null;
    const parseMaxDate = maxDate ? new Date(maxDate) : undefined;
    const parseMinDate = minDate ? new Date(minDate) : undefined;

    const dateHandler = (date: Date) => {
        if (date) {
            const formattedDate = date.toISOString();
            handleDateChange(formattedDate);
        } else {
            handleDateChange(null);
        }
    }

    return (
        <div style={{ width: fullWidth ? "100%" : "auto" }}>
            <DatePicker
                selected={parsedDate}
                onChange={dateHandler}
                minDate={parseMinDate}
                maxDate={parseMaxDate}
                showIcon
                showWeekNumbers
                dateFormat="MM/dd/yyyy"
                placeholderText={placeholder}
                shouldCloseOnSelect={true}
                fixedHeight
                style={{ height:"40px !important"}}
                customInput={
                    <TextField
                        fullWidth={fullWidth}
                        name={name}
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                    />
                }
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                    </svg>
                }
            />
        </div>
    );
};

export default CustomDatePickerNew;