import ModalWrapper from '../wrappers/ModalWrapper'
import { ADDRESS_GROUPS, AU_STATES, COUNTRIES, NZ_STATES, PALLET_ACCOUNT_ACTION, PALLET_ACCOUNT_TYPE, STATE_TYPE, users } from '../lib/constants'
import { useEffect, useState } from 'react';
import MultiSelectDropDownComp from '../ui/MultiSelectDropDownComp';
import SelectorComp from '../ui/SelectorComp';
import { Box, Button, Grid } from '@material-ui/core';
import InputBox from '../ui/InputBox';
import { styled } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Form, Formik } from 'formik';
import { IAddress } from '../../../../Application/DTOs/AddressDto/AddressDto.types';
import { toast } from 'react-toastify';
import { TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { WebStorage } from '../../Utilities/WebStorage';

interface EditAddressModalProps {
  open: boolean;
  close: (isSaved?: boolean) => void
  addressId: number | string
  addresses: IAddress[]
  fetchAddresses: () => void
}
const commonFlexStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
};
const ButtonFlex = styled(Box)({
  ...commonFlexStyles,
  gap: "20px"
});
const MarginSpace = styled(Box)({
  padding: "10px 0"
});

const ButtonContainer = styled(Box)({
  ...commonFlexStyles,
  margin: "20px 0"
});

const storage = new WebStorage();

const EditAddressModal = ({ open, close, addressId, addresses,fetchAddresses}: EditAddressModalProps) => {
  const [togglePallet, setTogglePallet] = useState<boolean>(false)

  const [initialValues, setInitialValues] = useState({
    addressId:0,
    searchaddress: "",
    companyId: 0,
    street_number: "",
    address_id: 0,
    is_common: false,
    is_land_freight:false,
    is_sea_freight:false,
    country: "",
    groupType: "",
    companyName: "",
    mpdId: "",
    address: "",
    timeslot: "",
    receivingHours: "",
    instruction: "",
    palletAccountType: "",
    palletAccountAction: "",
    palletAccount: "",
    stateRegionType: "",
    stateType: "",
    suburbCity: "",
    postcode: "",
    latitude: "",
    longitude: ""
  });

  useEffect(() => {
    if (addresses?.length && addressId) {
      const singleAddress = addresses?.find((item) => item.id === addressId);    
      if (singleAddress) {
        setInitialValues({
          searchaddress: "",
          addressId: singleAddress.id || 0,
          companyId: singleAddress.company_id || 0,
          street_number: singleAddress.street_number || "",
          address_id:singleAddress.id || 0,
          is_common: singleAddress.is_common || false,
          is_land_freight:singleAddress.is_land_freight || false,
          is_sea_freight:singleAddress.is_sea_freight || false,
          country: singleAddress.country || "",
          groupType:singleAddress.address_group|| "",
          companyName: singleAddress.company_name || "",
          mpdId: "",
          address: singleAddress.street_number || "",
          timeslot: singleAddress.email || "",
          receivingHours: singleAddress.receiving_hours || "",
          instruction: singleAddress.instruction || "",
          palletAccountType: "",
          palletAccountAction: "",
          palletAccount: "",
          stateRegionType: singleAddress.state || "",
          stateType: singleAddress.state_type||"",
          suburbCity: singleAddress.city || "",
          postcode: singleAddress.postcode || "",
          latitude: singleAddress.latitude || "",
          longitude: singleAddress.longitude || ""
        });
      }
    }
  }, [addresses, addressId]);


  const handleTogglePallet = () => {
    setTogglePallet((prev) => !prev);
  };
  const handleSubmit = async (values: any, { setSubmitting,resetForm }: any) => {    
    setSubmitting(true);

    const myHeaders = new Headers({
      "Content-Type": "application/json",
     "Authorization": `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
    });

    const raw = JSON.stringify({
      address_group: values.groupType,
      city: values.suburbCity,
      email: values.timeslot,
      country: values.country,
      postcode:values.postcode,
      state: values.stateRegionType,
      street_number: values.address,
      instruction: values.instruction,
      receiving_hours: values.receivingHours,
      company_id: values.companyId,
      latitude: values.latitude,
      longitude: values.longitude,
      is_common: values.is_common,
      is_land_freight: values.is_land_freight,
      is_sea_freight: values.is_sea_freight,
      company_name: values.companyName,
      addressId: values.addressId,
      state_type: values.stateType,
      palletAccounts: [],
      mpd_id: values.mpdId
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(process.env.REACT_APP_NEW_BASE_URL +"/v1/address/update", requestOptions);
      const result = await response.json();
      if(result.status_code===201 && result.success===true){
        toast.success("Address updated successfully");
        fetchAddresses()
        handleClose(resetForm);
      }
      else if(result.status_code===400 && result.success===false){
        toast.error(result.message);
      }    
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };
  const handleClose = (resetForm: () => void) => {
    resetForm(); 
    close();
};

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, handleChange, values, resetForm }) => (

          <ModalWrapper
            open={open}
            onClose={() => handleClose(resetForm)} 
            heading="Edit Address"
          >
            <Form>
              <MarginSpace>
                <MultiSelectDropDownComp
                  options={users}
                  label="Select Address"
                  value={values.searchaddress}
                  getOptionLabel={(option) => option.name}
                  getOptionId={(option) => option.id}
                  onChange={handleChange}
                  name="searchaddress"
                />
                
              </MarginSpace>
              <MarginSpace>
                <Grid container spacing={2} >
                  <Grid item xs={6} >
                    <SelectorComp
                      label="Country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      options={COUNTRIES}
                    />
                  </Grid>
                  <Grid item xs={6} >
                    <SelectorComp
                      label="Group"
                      onChange={handleChange}
                      options={ADDRESS_GROUPS}
                      value={values.groupType}
                      name={'groupType'}
                    />
                  </Grid>

                </Grid>
              </MarginSpace>

              <MarginSpace>
                <Grid container spacing={2}>

                  <Grid item xs={6} >
                    <InputBox
                      label="Company Name"
                      onChange={handleChange}
                      name="companyName"
                      type="text"
                      disabled={false}
                      value={values.companyName}
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <InputBox
                      label="MPD ID"
                      onChange={handleChange}
                      name="mpdId"
                      type="text"
                      disabled={false}
                      value={values.mpdId}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <MarginSpace>
                <Grid container spacing={2}>
                  <Grid item xs={6} >
                    <InputBox
                      label="Address"
                      onChange={handleChange}
                      name="address"
                      type="text"
                      value={values.address}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <InputBox
                      label="Timeslot Email"
                      onChange={handleChange}
                      name="timeslot"
                      type="text"
                      disabled={false}
                      value={values.timeslot}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <MarginSpace>
                <InputBox
                  label="Receiving Hours"
                  multiline
                  onChange={handleChange}
                  name="receivingHours"
                  type="text"
                  disabled={false}
                  value={values.receivingHours}
                />
              </MarginSpace>

              <MarginSpace>
                <InputBox
                  label="Instructions"
                  multiline
                  onChange={handleChange}
                  name="instruction"
                  type="text"
                  disabled={false}
                  value={values.instruction}
                />
              </MarginSpace>
              {togglePallet && <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="Pallet Account Type"
                      onChange={handleChange}
                      options={PALLET_ACCOUNT_TYPE}
                      value={values.palletAccountType}
                      name={'palletAccountType'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="Pallet Account Action"
                      onChange={handleChange}
                      options={PALLET_ACCOUNT_ACTION}
                      value={values.palletAccountAction}
                      name={'palletAccountAction'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Pallet Account"
                      onChange={handleChange}
                      name="palletAccount"
                      type="text"
                      disabled={false}
                      value={values.palletAccount}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>}
              <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="State/ Region"
                      onChange={handleChange}
                      value={values.stateRegionType}
                      name={'stateRegionType'}
                      options={values.country === "australia" ? AU_STATES : values.country === "new_zealand" ? NZ_STATES : []}

                    />
                  </Grid>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="State Type"
                      onChange={handleChange}
                      options={STATE_TYPE}
                      value={values.stateType}
                      name={'stateType'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Suburb/ City"
                      onChange={handleChange}
                      name="suburbCity"
                      type="text"
                      disabled={false}
                      value={values.suburbCity}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <InputBox
                      label="Postcode"
                      onChange={handleChange}
                      name="postcode"
                      type="text"
                      disabled={false}
                      value={values.postcode}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Latitude"
                      onChange={handleChange}
                      name="latitude"
                      type="text"
                      disabled={false}
                      value={values.latitude}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Longitude"
                      onChange={handleChange}
                      name="longitude"
                      type="text"
                      disabled={false}
                      value={values.longitude}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <ButtonContainer>
                <Button variant="contained" color="primary" type='button' onClick={handleTogglePallet}>Add Pallet</Button>
                <ButtonFlex>
                  <Button variant="contained" color="default" onClick={() => handleClose(resetForm)} disabled={isSubmitting}>Cancel</Button>
                  <Button variant="contained" color="primary" startIcon={<EditIcon />} type='submit' disabled={isSubmitting}>Edit</Button>
                </ButtonFlex>
              </ButtonContainer>
            </Form>
          </ModalWrapper>
        )}

      </Formik>
    </>
  )
}

export default EditAddressModal