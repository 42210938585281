import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import { useCallback, useEffect, useState, useMemo } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import DialpadIcon from "@material-ui/icons/Dialpad";
import HomeIcon from "@material-ui/icons/Home";
import MenuItem from "@material-ui/core/MenuItem";

import {
  COUNTRIES,
  USER_ROLES,
  VEHICLE_TYPES_BY_COUNTRY,
  COUNTRY_INSURANCES,
  LANGUAGES,
} from "../../../Types/Constants";
import { ICustomerInfo } from "../../../../../Application/DTOs/CustomerDto/CustomerDto.types";
import { Customer } from "../../../Api/Customer/Customer";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { getLabelForRegistrationNumberByCountry } from "../../../Utilities/Country";
import { isNumeric } from "../../../Utilities/Regex";
import { isNull, isEmpty } from "lodash";
import isEmail from "validator/lib/isEmail";
import { Hidden } from "@material-ui/core";
import { ICustomerRegisterProps } from "./CustomerRegister.types";
import TermsOfServiceDialog from "../../Dialogs/TermsOfServiceDialog/TermsOfServiceDialog";
import FileInput from "../../Common/FileInput/FileInput";
import useQuery from "../../../hooks/useQuery";
import { WebStorage } from "../../../Utilities/WebStorage";
import { setLanguage } from "../../../Redux/Language/actions";
import MapAutocomplete from "../../Common/MapAutocomplete/MapAutocomplete";
import MapAddress from "../../../BP/MapAddress";
import { ICompanyInfo } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { Company } from '../../../Api/Company/Company'
import { AppState } from '../../../Redux';

const useStyles = makeStyles((theme) => ({
  driverRegisterBox: {
    backgroundColor: "white",
    padding: "40px 15px",
    // borderRadius: "20px",
    // boxShadow: "2px 3px 6px -1px rgba(0,0,0,0.5)",
    overflowY: "auto",
    // height: "95vh",
  },
  logo: {
    width: "150px",
    height: "150px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    minWidth: "100%",
  },
  title: {
    fontWeight: "bold",
  },
  gap: {
    marginTop: theme.spacing(3),
  },
  btnRegister: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  btnBack: {
    backgroundColor: "grey",
    color: "white",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  termsOfServiceBtn: {
    fontStyle: "italic",
    background: "none",
    border: "none",
    padding: "0",
    textDecoration: "underline",
    color: "#069",
    cursor: "pointer",
  },
}));

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;
const { CUSTOMER } = USER_ROLES;

interface Languages {
  en: string;
  jp: string;
}

const customerApi = new Customer();

function CustomerRegister(props: ICustomerRegisterProps) {
  const { hideBack } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t, i18n] = useTranslation("CustomerRegister");
  const [showPass, setShowPass] = useState<boolean>(false);
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user.response);

  const handleBackButtonClick = () => {
    history.push(routes.home.root + routes.home.customer_management.root + routes.home.customer_management.customer_management);
  };
  const companyApi = new Company()
  const { ADMIN_USER, SUPER_ADMIN, DRIVER_MANAGER } = USER_ROLES;

  const isUserAdmin = user?.type === ADMIN_USER;
  const isSuperAdmin = user?.type === SUPER_ADMIN;

  const [companyId, setCompanyId] = useState<any>('');
  const [company, setCompanyList] = useState<ICompanyInfo[]>([])

  const [country, setCountry] = useState(AUSTRALIA);
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileConfirm, setMobileConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUEN, setCompanyUEN] = useState("");
  const [streetNameAddress, setStreetNameAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState('');
  const [latitude, setLatitude] = useState<string>('')
  const [longitude, setLongitude] = useState<string>('')
  const [hasSearchAddress, setHasSearchAddress] = useState(false)


  const [agreedTos, setAgreedTos] = useState(false);
  const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false);
  const params = useQuery();
  const [selectedLanguage] = useState(params.get("lang") ?? "en");
  const [isLoading, setIsLoading] = useState(selectedLanguage !== "en");
  const handleMapAddressSelect = useCallback(
    (mapAddress: MapAddress) => {
      setHasSearchAddress(true)
      setStreetNameAddress([mapAddress.streetNum, mapAddress.route].join(' '))
      setState(mapAddress.state)
      setPostalCode(mapAddress.postalCode)
      setCity(mapAddress.city)
      setLatitude(String(mapAddress.lat))
      setLongitude(String(mapAddress.long))
    },
    [],
  )
  // Set language based via url
  useEffect(() => {
    const lang: Languages = {
      en: LANGUAGES.ENGLISH,
      jp: LANGUAGES.JAPANESE,
    };
    let _lang = selectedLanguage as keyof Languages;
    let value = lang[_lang];
    i18n.changeLanguage(value);
    dispatch(setLanguage(value));
    setIsLoading(false);
  }, [dispatch, i18n, selectedLanguage]);

  const handleTermsOfServiceDialogClose = useCallback(() => {
    setIsTermsOfServiceDialogOpen(false);
  }, []);

  const handleOnRegisterClick = () => {
    //TODO: add validations

    if (isNull(companyId)) {
      toast.warning(t("select_a_company"));
      return;
    }
    if (isNull(country) || isEmpty(country)) {
      toast.warning(t("country_cannot_be_empty"));
      return;
    }
    if (email === "") {
      toast.warning(t("email_not_empty"));
      return;
    } else {
      if (!isEmail(email)) {
        toast.warning(t("email_invalid_format"));
        return;
      }
    }
    if (emailConfirm === "") {
      toast.warning(t("confirm_email_not_empty"));
      return;
    } else if (!isEmail(emailConfirm)) {
      toast.warning(t("confirm_email_invalid_format"));
      return;
    }

    if (email !== "" && emailConfirm !== "" && email !== emailConfirm) {
      toast.warning(t("email_not_match"));
      return;
    }

    // if (!isNumeric(mobile) || !isNumeric(mobileConfirm)) {
    //   toast.warning(t("mobile_not_number"));
    //   return;
    // }

    // if (isNull(mobile) || isEmpty(mobile)) {
    //   toast.warning(t("mobile_not_empty"));
    //   return;
    // } else {
    //   if (mobile !== mobileConfirm) {
    //     toast.warning(t("mobile_not_match"));
    //     return;
    //   }
    // }

    if (isNull(firstName) || isEmpty(firstName)) {
      toast.warning(t("firstname_not_empty"));
      return;
    }

    if (isNull(lastName) || isEmpty(lastName)) {
      toast.warning(t("lastname_not_empty"));
      return;
    }

    if (password === "" || passwordConfirm === "") {
      toast.warning(t("password_not_null"));
      return;
    } else if (password.length < 6) {
      toast.warning(t("invalid_password_length"));
      return;
    } else if (password !== passwordConfirm) {
      toast.warning(t("password_did_not_match"));
      return;
    }

    if (isNull(companyName) || isEmpty(companyName)) {
      toast.warning(t("company_name_not_empty"));
      return;
    }

    // if (country === PHILIPPINES) {
    //   if (companyUEN === "") {
    //     toast.warning(t("tin_not_empty"));
    //     return;
    //   } else if (!isNumeric(companyUEN)) {
    //     toast.warning(t("invalid_tin"));
    //     return;
    //   }
    // }

    // if (isNull(companyUEN) || isEmpty(companyUEN)) {
    //   if (country === JAPAN) {
    //     toast.warning(t("company_registration_number_not_empty"));
    //     return;
    //   } else {
    //     toast.warning(t("eun_not_empty"));
    //     return;
    //   }
    // }

    if (postalCode !== "" && !isNumeric(postalCode)) {
      toast.warning(t("postal_code_not_numeric"));
      return;
    }
    if (isEmpty(streetNameAddress) || isEmpty(country) || isEmpty(postalCode) || isEmpty(city)) {
      toast.warning(t("Invalid Address"));
      return;
    }


    // const formData = new FormData();
    const payload: ICustomerInfo = {
      company_id: companyId,
      city,
      address_group: 'customer',
      company_name: companyName,
      company_uen: '',
      contact_phone: mobile,
      email,
      first_name: firstName,
      last_name: lastName,
      mobile,
      password,
      postcode: postalCode,
      street_number: streetNameAddress,
      userType: CUSTOMER,
      is_approved: true,
      country,
      state,
      latitude,
      longitude,
      instruction: ''
    };
    // for (const key in payload) {
    //   let value = payload[key as keyof ICustomerInfo];

    //   formData.append(key, value as any);
    // }


    dispatch(setLoader(true));
    customerApi
      .create(payload)
      .then(() => {
        dispatch(setLoader(false));
        toast.success(t("succcess_registering_customer"));
        handleBackButtonClick()
      })
      .catch((err: any) => {
        dispatch(setLoader(false));
        toast.error(t(err.response?.data || "error_registering_customer"));
        console.log("err", err);
      });
  };

  const companyListOptions = useMemo(
    () => {

      return company.map((company, i) => (
        <MenuItem key={i} value={company?.id}>
          {company?.companyName}
        </MenuItem>
      ))


    },

    [company]
  );
  const getCompanies = useCallback(
    () => {
      dispatch(setLoader(true))
      companyApi.getAll()
        .then((response) => {
          dispatch(setLoader(false))
          setCompanyList(response.data)
        })
        .catch((err: any) => {
          dispatch(setLoader(false))
          setCompanyList([])
          console.log('err', err)
          toast.error(t('error_getting_companies'))
        })
    },
    [dispatch, t],
  )
  useEffect(() => {
    if(!isUserAdmin && !isSuperAdmin){
      setCompanyId(user.company.id)
    }
    getCompanies()

  }, [])
  if (isLoading) {
    return <></>;
  }

  return (
    <Grid  >
      <TermsOfServiceDialog
        agreed={agreedTos}
        onCheckBoxCheck={(agree) => {
          setAgreedTos(agree);
        }}
        open={isTermsOfServiceDialogOpen}
        handleClose={handleTermsOfServiceDialogClose}
        country={country}
      />
      <Box className={classes.driverRegisterBox}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid lg={12} item>
            <Typography align="center" variant="h4" component="h4">
              {t("customer_registration")}
            </Typography>
          </Grid>
          {(isUserAdmin || isSuperAdmin) ?
            (<Grid xs={12} lg={12} item>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel >
                  {t('posting_company')}
                </InputLabel>
                <Select
                  value={companyId}
                  // readOnly={hasAllPrivilege ? false : true}
                  // disabled={hasAllPrivilege ? false : true}
                  onChange={(e) => {
                    const companyId = e.target.value || '';
                    const selectedCompany = company.find((c) => c.id === companyId);

                    if (selectedCompany) {
                      setCountry(selectedCompany.country);
                    }

                    setCompanyId(companyId);
                  }}
                >
                  {companyListOptions}
                </Select>

              </FormControl>

            </Grid>
            )
            : null
          }
          {/* <Grid xs={12} lg={12} item>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel style={{ fontWeight: "bold" }}>
                {t("select_your_country")}
              </InputLabel>
              <Select
                className={classes.formControl}
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value as string);
                }}
              >
                <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
              </Select> 
            </FormControl>
          </Grid> */}

          <Grid xs={12} lg={6} item>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("provide_email_address") + "*"}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={emailConfirm}
              onChange={(e) => setEmailConfirm(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("confirm_email_address") + "*"}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          {/* <Grid xs={12} lg={6} item>
            <TextField
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("provide_mobile_number") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DialpadIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={mobileConfirm}
              onChange={(e) => setMobileConfirm(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("confirm_mobile_number") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DialpadIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid> */}
          <Grid xs={12} lg={6} item>
            <TextField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_first_name") + "*"}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_last_name") + "*"}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_password") + "*"}
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("confirm_password") + "*"}
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>
          <Grid xs={12} lg={6} item>
            <TextField
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_company_name") + "*"}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          {/* <Grid xs={12} lg={6} item>
            <TextField
              value={companyUEN}
              onChange={(e) => setCompanyUEN(e.target.value)}
              variant="outlined"
              fullWidth
              label={t(getLabelForRegistrationNumberByCountry(country)) + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid> */}
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>

          <Grid lg={12} item>
            <Typography className={classes.title} align="left" variant="body1">
              {t("main_address_details")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={12} item>

            <MapAutocomplete onAddressSelect={handleMapAddressSelect} />

            {/* <TextField
              value={streetNameAddress}
              onChange={(e) => setStreetNameAddress(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_street_num_n_name") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HomeIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            /> */}
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={city}
              onChange={(e) => setCity(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_suburb_or_city") + "*"}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_postal_code") + "*"}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>

          {/* <Grid md={12} item>
            <Typography variant="caption" display="block" gutterBottom>
              {t("i_agree_to_tos")}{" "}
              <button
                onClick={() => setIsTermsOfServiceDialogOpen(true)}
                className={classes.termsOfServiceBtn}
              >
                {t("click_here_to_read_tos")}
              </button>{" "}
              {t("and_our")}
              <a href="https://moveit4u.com.au/privacypolicy/" target="_blank">
                {t("privacy_policy")}
              </a>{" "}
              {t("for_the_use_of_the_moveit4u_website_and_mobile_app")}{" "}
              <a href="https://moveit4u.com.au/pricing/" target="_blank">
                {t("pricing_page")}
              </a>
              .
            </Typography>
          </Grid> */}

          <Grid xs={12} lg={6} item>
            <Button
              onClick={handleOnRegisterClick}
              startIcon={<DoneOutlineIcon />}
              className={classes.btnRegister}
              variant="contained"
              fullWidth
            >
              {t("register")}
            </Button>
          </Grid>
          {!hideBack ? (
            <Grid xs={12} lg={6} item>
              <Button
                startIcon={<ArrowBackIcon />}
                className={classes.btnBack}
                variant="contained"
                fullWidth
                onClick={handleBackButtonClick}
              >
                {t("back")}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
}

export default CustomerRegister;
