import React from 'react';
import TextField,{ TextFieldProps } from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';

interface CustomTimePickerProps extends Omit<TextFieldProps, 'type' | 'value' | 'onChange'> {
    label: string;
    value: string; 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean; 
    error?: any; 
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({ label, value, onChange, readOnly = false,error, ...otherProps }) => {
    return (
        <FormControl fullWidth variant="outlined" >
        <TextField
            id="time"
            label={label}
            fullWidth
            variant="outlined"
            type="time"
            value={value}
            onChange={onChange}
            error={!!error}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                readOnly,
            }}
            {...otherProps} 
        />
             <FormHelperText style={{ color: "#f44336" }}>{error}</FormHelperText>
             </FormControl>
    );
};

export default CustomTimePicker;
