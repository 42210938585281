import React, { memo, ReactNode } from 'react';
import { Modal, Typography, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/core/styles';

interface ModalWrapperProps {
  heading?: string;
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  showCross?: boolean;
}

const StyledTypo = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 600,
  marginBottom: '20px',
}));

const Flex = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  heading,
  children,
  open,
  onClose,
  showCross = true,
}) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:"935px",
    bgcolor: 'background.paper',
    border: 'none' ,
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
    borderRadius: '4px',
    zIndex:999,
    
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={modalStyle}>
        <Flex>
          <StyledTypo id="modal-modal-title" variant="h6">
            {heading || 'Default Heading'}
          </StyledTypo>
          {showCross && (
            <div>
              <CloseIcon fontSize="medium" style={{ cursor: 'pointer' }} onClick={onClose} />
            </div>
          )}
        </Flex>
        {children}
      </Box>
    </Modal>
  );
};

export default memo(ModalWrapper);
