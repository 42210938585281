import makeStyles from "@material-ui/core/styles/makeStyles"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import { useTranslation } from "react-i18next"
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import React, { useCallback, useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom"
import { routes } from "../../../routes"
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import { COUNTRIES, COMPANY_TYPES, COUNTRY_INSURANCES } from "../../../Types/Constants"
import { getCountryByAbbr, getLabelForRegistrationNumberByCountry } from "../../../Utilities/Country"
import { isNumeric } from "../../../Utilities/Regex"
import { Company } from "../../../Api/Company/Company"
import { toast } from "react-toastify"
import isEmail from 'validator/lib/isEmail';
import MapAutocomplete from '../../Common/MapAutocomplete/MapAutocomplete'
import MapAddress from "../../../BP/MapAddress"
import TermsOfServiceDialog from "../../Dialogs/TermsOfServiceDialog/TermsOfServiceDialog"

const { FREIGHT_FORWARED, NVOCC, IMPORT_EXPORTER, SHIPPING_LINE, TRANSPORT_COMPANY } = COMPANY_TYPES

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const useStyles = makeStyles((theme) => ({
    companyRegisterBox: {
        backgroundColor: 'white',
        padding: '40px 15px',
        borderRadius: '20px',
        boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)',
        overflowY: 'auto',
        height: '95vh',
    },
    logo: {
        width: '150px',
        height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    gap: {
        marginTop: theme.spacing(3)
    },
    btnRegister: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    btnBack: {
        backgroundColor: 'grey',
        color: 'white',
        '&:hover': {
            backgroundColor: 'grey'
        }
    },
    termsOfServiceBtn: {
        fontStyle: 'italic',
        background: 'none',
        border: 'none',
        padding: '0',
        textDecoration: 'underline',
        color: '#069',
        cursor: 'pointer'
    }
}))

const companyApi = new Company()

function CompanyRegister() {
    const history = useHistory()
    const classes = useStyles()
    const [t] = useTranslation('CompanyRegister')

    const [showPass, setShowPass] = useState<boolean>(false)
    const [country, setCountry] = useState<string>(AUSTRALIA)
    const [companyType, setCompanyType] = useState<string>('')
    const [companyName, setCompanyName] = useState('')
    const [companyUen, setCompanyUen] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [confirmMobile, setConfirmMobile] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [streetAndName, setStreetAndName] = useState('')
    const [suburbOrCity, setSuburbOrCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [insurances, setInsurances] = useState<string[]>([])
    const [accountName, setAccountName] = useState('')
    const [accountPhone, setAccountPhone] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [operationsName, setOperationsName] = useState('')
    const [operationsPhone, setOperationsPhone] = useState('')
    const [operationsEmail, setOperationsEmail] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [isDetailsForOperationsSame, setIsDetailsForOperationsSame] = useState(false)
    const [isDetailsForAccountSame, setIsDetailsForAccountSame] = useState(false)
    const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)
    const [waitingTime, setWaitingTime] = useState('')
    const [waitingTimeCharge, setWaitingTimeCharge] = useState('')
    const [agreedTos, setAgreedTos] = useState(false)
    const [importerName, setImporterName] = useState('')
    const [importerPhone, setImporterPhone] = useState('')
    const [importerEmail, setImporterEmail] = useState('')
    const [exporterName, setExporterName] = useState('')
    const [exporterPhone, setExporterPhone] = useState('')
    const [exporterEmail, setExporterEmail] = useState('')
    const [latitude, setLatitude] = useState<number | undefined>(undefined)
    const [longitude, setLongitude] = useState<number | undefined>(undefined)

    const INSURANCES = COUNTRY_INSURANCES[country] ?? []

    const handleTermsOfServiceDialogClose = useCallback(
        () => {
            setIsTermsOfServiceDialogOpen(false)
        },
        [],
    )

    const handleBackButtonClick = () => {
        history.push(routes.landing.login[0])
    }

    const handleRegisterButtonClick = () => {

        // if (country === '') {
        //     toast.warning(t('country_cannot_be_empty'))
        //     return
        // }

        if (companyType === '') {
            toast.warning(t('company_type_not_empty'))
            return
        }

        if (companyName === '') {
            toast.warning(t('company_name_not_empty'))
            return
        }

        // if (country === PHILIPPINES) {
        //     if (companyUen === '') {
        //         toast.warning(t('tin_not_empty'))
        //         return
        //     } else if (!isNumeric(companyUen)) {
        //         toast.warning(t('invalid_tin'))
        //         return
        //     }

        // }

        if (companyUen === '') {
            if (country === AUSTRALIA) {
                toast.warning(t('abn_not_empty'))
                return
            } else if (country === JAPAN) {
                toast.warning(t('company_registration_number_not_empty'))
                return
            } else {
                toast.warning(t('eun_not_empty'))
                return
            }
        }

        if (email === '' || confirmEmail === '') {
            toast.warning(t('email_not_empty'))
            return
        } else {
            if (!isEmail(email) || !isEmail(confirmEmail)) {
                toast.warning(t('email_invalid_format'))
                return
            }

            if (email !== confirmEmail) {
                toast.warning(t('email_not_match'))
                return
            }
        }

        if (mobile === '' || confirmMobile === '') {
            toast.warning(t('mobile_not_empty'))
            return
        } else if (!isNumeric(mobile) || !isNumeric(confirmMobile)) {
            toast.warning(t('mobile_not_number'))
            return
        } else if (mobile !== confirmMobile) {
            toast.warning(t('mobile_not_match'))
            return
        }

        if (firstName === '') {
            toast.warning(t('firstname_not_empty'))
            return
        }

        if (lastName === '') {
            toast.warning(t('lastname_not_empty'))
            return
        }

        if (password === '') {
            toast.warning(t('password_not_null'))
            return
        } else if(passwordConfirm === '') {
            toast.warning(t('confirm_password_not_empty'))
            return
        }
        else if (password.length < 6) {
            toast.warning(t('invalid_password_length'))
            return
        } else if (password !== passwordConfirm) {
            toast.warning(t('password_did_not_match'))
            return
        }

        if (streetAndName === '') {
            toast.warning(t('street_name_not_empty'))
            return
        }

        if (suburbOrCity === '') {
            toast.warning(t('suburb_city_not_empty'))
            return
        }

        if (postalCode === '') {
            toast.warning(t('postcode_not_empty'))
            return
        }

        if (accountName === '') {
            toast.warning(t('account_name_not_empty'))
            return
        }

        if (accountPhone === '') {
            toast.warning(t('account_phone_not_empty'))
            return
        } else if (!isNumeric(accountPhone)) {
            toast.warning(t('account_phone_invalid'))
            return
        }

        if (accountEmail === '') {
            toast.warning(t('account_email_not_empty'))
            return
        } else if (!isEmail(accountEmail)) {
            toast.warning(t('account_invalid_email'))
            return
        }


        // if (insurances.length < 4) {
        //     toast.warning(t('select_coverage'))
        //     return
        // }

        if (operationsName === '') {
            toast.warning(t('operation_name_not_empty'))
            return
        }

        if (operationsPhone === '') {
            toast.warning(t('operation_phone_not_empty'))
            return
        } else if (!isNumeric(operationsPhone)) {
            toast.warning(t('operation_phone_invalid'))
            return
        }

        if (operationsEmail === '') {
            toast.warning(t('operation_email_not_empty'))
            return
        } else if (!isEmail(operationsEmail)) {
            toast.warning(t('operation_invalid_email'))
            return
        }
      
        // if(companyType === TRANSPORT_COMPANY && (waitingTime !== '' || waitingTimeCharge !== '')) {
        //     if(!isNumeric(waitingTime)) {
        //         toast.warning(t('waiting_time_invalid'))
        //         return
        //     } else if(!isNumeric(waitingTimeCharge)) {
        //         toast.warning(t('waiting_time_charge_invalid'))
        //         return
        //     }
    
        // }

        if(!agreedTos) {
            toast.warning(t('tc_agree'))
            return
        }

        companyApi.register({
            username: '',
            email,
            firstName,
            lastName,
            password,
            password_confirm: passwordConfirm,
            type: 'COMPANY',
            companyType: companyType || '',
            mobile,
            companyName,
            companyUen,
            addressStreet: streetAndName,
            addressCity: suburbOrCity,
            addressPostalCode: postalCode,
            accountContactName: accountName,
            accountContactPhone: accountPhone,
            accountContactEmail: accountEmail,
            operationsContactName: operationsName,
            operationsContactPhone: operationsPhone,
            operationsContactEmail: operationsEmail,
            promoCode,
            insurances,
            country: country || '',
            // waitTime: waitingTime,
            // waitTimeCharge: waitingTimeCharge,
            importerName,
            importerEmail,
            importerPhone,
            exporterEmail,
            exporterName,
            exporterPhone,
            latitude,
            longitude
        })
            .then(() => {
                toast.success(t('registration_success'))
                history.push(routes.landing.login[0])
            })
            .catch((e: any) => {
                toast.error(t(e.response?.data || 'an_error_occurred_while_registering'))
                console.log(e)
            })
    }

    const handleMapAddressSelect = useCallback(
        (mapAddress: MapAddress) => {
            setCountry(getCountryByAbbr(mapAddress.country))
            setStreetAndName([mapAddress.streetNum, mapAddress.route].join(' '))
            setSuburbOrCity(mapAddress.city)
            setPostalCode(mapAddress.postalCode)
            setLatitude(mapAddress.lat)
            setLongitude(mapAddress.long)
        },
        [],
    )

    const handleInsuranceCheck = useCallback(
        (isChecked: boolean, insurance: string) => {
            if (isChecked) {
                if (!insurances.includes(insurance)) {
                    insurances.push(insurance)
                    setInsurances([...insurances])
                }
            } else {
                setInsurances(insurances.filter(ins => ins !== insurance))
            }
        },
        [insurances],
    )

    return (
        <Container maxWidth="lg">
            <TermsOfServiceDialog agreed={agreedTos} onCheckBoxCheck={(agree) => { setAgreedTos(agree) }} open={isTermsOfServiceDialogOpen} handleClose={handleTermsOfServiceDialogClose} country={country} />
            <Box className={classes.companyRegisterBox}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid md={12} item>
                        <Typography align="center" variant="h4" component="h4">
                            {t('company_registration')}
                        </Typography>
                    </Grid>

                    <Grid md={12} item>
                        <MapAutocomplete onAddressSelect={handleMapAddressSelect} />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={latitude}
                            variant="outlined"
                            fullWidth
                            label={t('latitude')}
                            disabled
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={longitude}
                            variant="outlined"
                            fullWidth
                            label={t('longitude')}
                            disabled
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    {/* <Grid md={6} item>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_your_country')}</InputLabel>
                            <Select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value as string)
                                    setInsurances([])
                                }}
                            > */}
                                {/* <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem> */}
                                {/* <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem> */}
                                {/* <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem> */}
                                {/* <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem> */}
                            {/* </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid md={6} item>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_company_type') + "*"}</InputLabel>
                            <Select
                                value={companyType}
                                onChange={(e) => setCompanyType(e.target.value as string)}
                            >
                                <MenuItem value={NVOCC}>{t(NVOCC)}</MenuItem>
                                <MenuItem value={FREIGHT_FORWARED}>{t(FREIGHT_FORWARED)}</MenuItem>
                                <MenuItem value={TRANSPORT_COMPANY}>{t(TRANSPORT_COMPANY)}</MenuItem>
                                <MenuItem value={IMPORT_EXPORTER}>{t(IMPORT_EXPORTER)}</MenuItem>
                                <MenuItem value={SHIPPING_LINE}>{t(SHIPPING_LINE)}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_company_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyUen}
                            onChange={(e) => setCompanyUen(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t(`${getLabelForRegistrationNumberByCountry(country)}`) + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_email_address') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={confirmEmail}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('confirm_email_address') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_mobile_number') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={confirmMobile}
                            onChange={(e) => setConfirmMobile(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('confirm_mobile_number') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_first_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_last_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_password') + "*"}
                            type={showPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPass(!showPass)}>
                                            {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('confirm_password') + "*"}
                            type={showPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPass(!showPass)}>
                                            {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('address_details')}
                        </Typography>
                    </Grid>
                    <Grid md={12} item>
                        <TextField
                            value={streetAndName}
                            onChange={(e) => setStreetAndName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_street_num_n_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={suburbOrCity}
                            onChange={(e) => setSuburbOrCity(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_suburb_or_city') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_postal_code') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    <Grid md={12} item>
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={isDetailsForAccountSame} onChange={(e) => {
                                if (e.target.checked) {
                                    setAccountName(`${firstName} ${lastName}`)
                                    setAccountEmail(email)
                                    setAccountPhone(mobile)
                                } else {
                                    setAccountName('')
                                    setAccountEmail('')
                                    setAccountPhone('')
                                }
                                setIsDetailsForAccountSame(e.target.checked)
                            }} />}
                            label={t('use_account_details')}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_contact_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={accountPhone}
                            onChange={(e) => setAccountPhone(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_contact_phone') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={accountEmail}
                            onChange={(e) => setAccountEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_contact_email') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        {/* EMPTY */}
                    </Grid>
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('please_confirm_your_insurance_coverage')}
                        </Typography>
                    </Grid>
                    {INSURANCES.map((insurance, i) => (
                        <Grid key={i} md={(i % 2) === 0 ? 3 : 9} item>
                            <FormControlLabel
                                control={<Checkbox checked={insurances.includes(insurance)} onChange={(e) => handleInsuranceCheck(e.target.checked, insurance)} color="primary" />}
                                label={t(insurance)}
                            />
                        </Grid>
                    ))}
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    <Grid md={12} item>
                        <FormControlLabel
                            control={<Checkbox checked={isDetailsForOperationsSame} color="primary" onChange={(e) => {
                                if (e.target.checked) {
                                    setOperationsName(`${firstName} ${lastName}`)
                                    setOperationsEmail(email)
                                    setOperationsPhone(mobile)
                                } else {
                                    setOperationsName('')
                                    setOperationsEmail('')
                                    setOperationsPhone('')
                                }

                                setIsDetailsForOperationsSame(e.target.checked)
                            }} />}
                            label={t('use_operations_contact_details')}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={operationsName}
                            onChange={(e) => setOperationsName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_operations_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={operationsPhone}
                            onChange={(e) => setOperationsPhone(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_operations_phone') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={operationsEmail}
                            onChange={(e) => setOperationsEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_operations_email') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        {/* EMPTY */}
                    </Grid>
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    {
                        [NVOCC, FREIGHT_FORWARED].includes(companyType)
                            ? <>
                                <Grid md={12} item>
                                    <Typography className={classes.title} align="left" variant="body1">
                                        {t('importer_details')}
                                    </Typography>
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={importerName}
                                        onChange={e => setImporterName(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('provide_importer_name')}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={importerPhone}
                                        onChange={e => setImporterPhone(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('provide_importer_phone')}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={importerEmail}
                                        onChange={e => setImporterEmail(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('provide_importer_email')}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    {/* EMPTY */}
                                </Grid>
                                <Grid className={classes.gap} md={12} item>
                                    {/* GAP */}
                                </Grid>
                                <Grid md={12} item>
                                    <Typography className={classes.title} align="left" variant="body1">
                                        {t('exporter_details')}
                                    </Typography>
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={exporterName}
                                        onChange={e => setExporterName(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('provide_exporter_name')}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={exporterPhone}
                                        onChange={e => setExporterPhone(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('provide_exporter_phone')}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    <TextField
                                        value={exporterEmail}
                                        onChange={e => setExporterEmail(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('provide_exporter_email')}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} item>
                                    {/* EMPTY */}
                                </Grid>
                                <Grid className={classes.gap} md={12} item>
                                    {/* GAP */}
                                </Grid>
                            </>
                            : null
                    }

                   

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('promo_code')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_promotion_code')}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        {/* EMPTY */}
                    </Grid>
                    <Grid md={11} item>
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('i_agree_to_tos')} <button onClick={() => setIsTermsOfServiceDialogOpen(true)} className={classes.termsOfServiceBtn}>{t('click_here_to_read_tos')}</button> {t('and_our')}
                            <a rel="noreferrer" href="https://moveit4u.com.au/privacypolicy/" target="_blank">{t('privacy_policy')}</a> {t('for_the_use_of_the_moveit4u_website_and_mobile_app')} <a rel="noreferrer" href="https://moveit4u.com.au/pricing/" target="_blank">{t('pricing_page')}</a>.
                        </Typography>
                    </Grid>
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    <Grid md={6} item>
                        <Button
                            startIcon={<DoneOutlineIcon />}
                            className={classes.btnRegister}
                            variant="contained"
                            fullWidth
                            onClick={handleRegisterButtonClick}
                        >
                            {t('register')}
                        </Button>
                    </Grid>
                    <Grid md={6} item>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            className={classes.btnBack}
                            variant="contained"
                            fullWidth
                            onClick={handleBackButtonClick}
                        >
                            {t('back')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )

}


export default CompanyRegister