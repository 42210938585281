import ModalWrapper from '../wrappers/ModalWrapper'
import { ADDRESS_GROUPS, AU_STATES, COUNTRIES, NZ_STATES, PALLET_ACCOUNT_ACTION, PALLET_ACCOUNT_TYPE, users } from '../lib/constants'
import MultiSelectDropDownComp from '../ui/MultiSelectDropDownComp';
import SelectorComp from '../ui/SelectorComp';
import { Box, Button, Grid } from '@material-ui/core';
import InputBox from '../ui/InputBox';
import { styled } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import { AppState } from '../../Redux';
import { useSelector } from 'react-redux';
import { ICompanyInfo } from '../../../../Application/DTOs/CompanyDto/CompanyDto.type';
import { Address } from '../../Api/Address/Address';
import { toast } from 'react-toastify';

const addressService = new Address();
interface CreateAddressModalProps {
  open: boolean;
  close: (isSaved?: boolean) => void;
  fetchAddresses: () => void
}

const commonFlexStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
};
const ButtonFlex = styled(Box)({
  ...commonFlexStyles,
  gap: "20px"
});
const MarginSpace = styled(Box)({
  padding: "10px 0"
});

const ButtonContainer = styled(Box)({
  ...commonFlexStyles,
  margin: "20px 0"
});
const CreateAddressModal = ({ open, close,fetchAddresses}: CreateAddressModalProps) => {
  const companyDropdownData = useSelector((state: AppState) => state.company);
  const [togglePallet, setTogglePallet] = useState<boolean>(false)
  const initialValues = {
    searchaddress: "",
    country: "",
    company: "",
    groupType: "",
    companyName: "",
    mpdId: "",
    address: "",
    timeslotEmail: "",
    receivingHours: "",
    instruction: "",
    palletAccountType: "",
    palletAccountAction: "",
    palletAccount: "",
    stateRegionType: "",
    stateType: "",
    suburbCity: "",
    postcode: "",
    latitude: "",
    longitude: ""
  };

  const handleTogglePallet = () => {
    setTogglePallet((prev) => !prev);
  };

  const handleSubmit = async (values: any, { setSubmitting, resetForm  }: any) => {
    try {
      const newAddressData = {
        address: values.address,
        company_id: values.company,
        company_name: values.companyName,
        country: values.country,
        address_group: values.groupType,
        instruction: values.instruction,
        latitude: values.latitude,
        longitude: values.longitude,
        mpd_id: values.mpdId,
        postcode: values.postcode,
        receiving_hours: values.receivingHours,
        palletAccount: values.palletAccount,
        palletAccountAction: values.palletAccountAction,
        palletAccountType: values.palletAccountType,
        state: values.stateRegionType,
        state_type: values.stateType,
        city: values.suburbCity,
        email: values.timeslotEmail,
        is_common: true,
        is_land_freight: true,
        is_sea_freight: false,
        street_number: "", 
        palletAccounts: []
      }
      const response = await addressService.create(newAddressData)

      if (response?.status == 201) {
        toast.success(response?.data);
        resetForm();
        fetchAddresses();
        close(true);
      }
      else {
        toast.error('Please fill all required fields');
      }
    } catch (err) {
      toast.error('Please fill all required fields');
    }
  }


  const handleClose = (resetForm: () => void) => {
    resetForm(); 
    close();
};
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, handleChange, setFieldValue, values, errors, touched, resetForm }) => (

          <ModalWrapper
            open={open}
            onClose={() => handleClose(resetForm)} 
            heading="Create Address"
          >
            <Form>
              <MarginSpace>
                <MultiSelectDropDownComp<any>
                  options={users}
                  label="Select Address"
                  value={values.searchaddress}
                  getOptionLabel={(option) => option.name}
                  getOptionId={(option) => option.id}
                  onChange={handleChange}
                  name="searchaddress"
                />

              </MarginSpace>
              <MarginSpace>
                <Grid container spacing={2} >
                  <Grid item xs={6} >
                    <SelectorComp
                      label="Country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      options={COUNTRIES}
                    />
                  </Grid>
                  <Grid item xs={6} >

                    <MultiSelectDropDownComp<ICompanyInfo>
                      options={companyDropdownData.list}
                      label="Company"
                      name="company"
                      value={values.company}
                      getOptionLabel={(option) => option.companyName}
                      getOptionId={(option) => option.id}
                      onChange={(event, selectedOption) => {
                        setFieldValue("company", selectedOption ? selectedOption.id : "");
                      }}
                    />
                  </Grid>

                </Grid>
              </MarginSpace>
              <SelectorComp
                label="Group Type"
                onChange={handleChange}
                options={ADDRESS_GROUPS}
                value={values.groupType}
                name="groupType"
              />
              <MarginSpace>
                <Grid container spacing={2}>

                  <Grid item xs={6} >
                    <InputBox
                      label="Company Name"
                      onChange={handleChange}
                      name="companyName"
                      type="text"
                      value={values.companyName}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <InputBox
                      label="MPD ID"
                      onChange={handleChange}
                      name="mpdId"
                      type="text"
                      value={values.mpdId}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <MarginSpace>
                <Grid container spacing={2}>
                  <Grid item xs={6} >
                    <InputBox
                      label="Address"
                      onChange={handleChange}
                      name="address"
                      type="text"
                      value={values.address}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <InputBox
                      label="Timeslot Email"
                      onChange={handleChange}
                      name="timeslotEmail"
                      type="text"
                      disabled={false}
                      value={values.timeslotEmail}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <MarginSpace>
                <InputBox
                  label="Receiving Hours"
                  multiline
                  onChange={handleChange}
                  name="receivingHours"
                  type="text"
                  disabled={false}
                  value={values.receivingHours}
                />
              </MarginSpace>

              <MarginSpace>
                <InputBox
                  label="Instructions"
                  multiline
                  onChange={handleChange}
                  name="instruction"
                  type="text"
                  disabled={false}
                  value={values.instruction}
                />
              </MarginSpace>

              {togglePallet && <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="Pallet Account Type"
                      onChange={handleChange}
                      options={PALLET_ACCOUNT_TYPE}
                      value={values.palletAccountType}
                      name={'palletAccountType'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="Pallet Account Action"
                      onChange={handleChange}
                      options={PALLET_ACCOUNT_ACTION}
                      value={values.palletAccountAction}
                      name={'palletAccountAction'}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Pallet Account"
                      onChange={handleChange}
                      name="palletAccount"
                      type="text"
                      value={values.palletAccount}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>}

              <MarginSpace>
                <Grid container spacing={3}>
                  <Grid item xs={4} >
                    <SelectorComp
                      label="State/ Region"
                      onChange={handleChange}
                      options={values.country === "australia" ? AU_STATES : values.country === "new_zealand" ? NZ_STATES : []}
                      value={values.stateRegionType}
                      name={'stateRegionType'}
                    />
                  </Grid>

                  <Grid item xs={4} >
                    <InputBox
                      label="Suburb/ City"
                      onChange={handleChange}
                      name="suburbCity"
                      type="text"
                      value={values.suburbCity}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <InputBox
                      label="Postcode"
                      onChange={handleChange}
                      name="postcode"
                      type="text"
                      value={values.postcode}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>


              <MarginSpace>
                <Grid container spacing={3}>

                  <Grid item xs={6} >
                    <InputBox
                      label="Latitude"
                      onChange={handleChange}
                      name="latitude"
                      type="text"
                      value={values.latitude}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={6} >
                    <InputBox
                      label="Longitude"
                      onChange={handleChange}
                      name="longitude"
                      type="text"
                      value={values.longitude}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </MarginSpace>

              <ButtonContainer>
                <Button variant="contained" color="primary" onClick={handleTogglePallet} type='button'>Add Pallet</Button>
                <ButtonFlex>
                  <Button variant="contained" color="default" onClick={() => handleClose(resetForm)} >Cancel</Button>
                  <Button variant="contained" color="primary" startIcon={<AddIcon />} type='submit'>Create</Button>
                </ButtonFlex>
              </ButtonContainer>
            </Form>
          </ModalWrapper>
        )}

      </Formik>
    </>
  )
}

export default CreateAddressModal